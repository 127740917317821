// ----- 文字區塊 or 編輯器 ----- //
.article,
article {
    width: 100%;
    font-size: 100%;
    .unreset_iframe {
        width: 100%;
        height: 750px;
        @include pad {
            height: 350px;
        }
    }
}

// Google 驗證
.g-recaptcha {
    // width: 100%;
    // display: flex;
    // justify-content: center;
    // iframe{
    //     height: auto !important;
    // }
}

.unreset{
	line-height: initial;
    
	a{
		text-decoration: underline;
	}

	img{
		max-width: 100% !important;
		height: auto !important; 
	}

	p{
		font-size: 1rem /* 16/16 */;
		line-height: 1.5;
	}
}

.in-content{
    padding-top: 0.25em;

    @include setStyleRwd(1700px){
        padding-left: 2em;
        padding-right: 2em;
    }

    @include pad{
        padding-left: 1em;
        padding-right: 1em;
    }
}

.imgCenter-4-3{
    position: relative;
    @include setSize(100%,0);
    padding-bottom: 59.5%;
    overflow: hidden;
    img{
        @extend %imgCenter;
        max-height: none;
    }
}

// 邊框陰影
.boxShadow{
    box-shadow: 0 0 10px 5px rgba(0, 0, 0, 0.1) !important;
}

// 邊框
.bt-solid-c1{
    border-top: 1px #ccc solid;
}

// 有左/右內容區塊使用
.mainContent{
    overflow: hidden;
    @extend %setFlex;

    &-info{
        @include setMinRwd{
            width: 300px;

            &~.mainContent-list{
                max-width: calc(100% - 300px);
                padding-left: 45px;
                padding-right: 5px;
            }
        }

        @include setWidthRwd(1000px,1500px){
            padding-left: 1em;
            padding-right: 1em;
            &~.mainContent-list{
                padding-left: 1em;
                padding-right: 1em;
            }
        }

        @include setMaxRwd{
            .link-wrap{
                display: none;
                &.cur{
                    display: block;
                }
            }
        }
    }

    &-list{
        max-width: 100%;
        flex: 1;

        // 內頁用的card
        .card-title {
            --max-size: 20;
            font-weight: 500 !important;
        }

        .card-img-top{
            text-align: center;
            background: #e7e7e7;
            height: 0;
            padding-top: 55%;
            img {
                display: inline-block;
                width: auto;
                @extend %imgCenter;
            }
        }

        @include setMinRwd{
            .card-body{
                padding-bottom: 1.75em;
            }
        }

        @include setMaxRwd{
            padding-left: 1em;
            padding-right: 1em;
        }
    }

    @include pad1024{
        flex-direction: column;
    }
}

// 搭配的裝飾
.main-pageCircle-wrap{
    &.top-left{
        .pageCircle{
          left: 0;
        }
    }

    &.bottom-right{
      .pageCircle{
        top: auto;
        bottom: 0;
        left: auto;
        right: 0;
      }
    }
}

// 內頁左側連結
.link{
    &-wrap {
        ul {
            padding-left: get-vw(30px);
            margin-right: get-vw(30px);
            border-right: #d3d3d3 1px solid;
            >li {
                >a {
                    position: relative;
                    display: block;
                    line-height: 1.5;
                    color: $colorBase;
                    padding: 1rem 0.95rem 0.75rem;
                    padding-left: 1.7em;
                    background-color: transparent !important;
                    transition: 0.25s;
                    font-weight: 400;
                }

                position: relative;
                border-bottom: #d3d3d3 2px solid;
                &::before{
                    content: '';
                    display: block;
                    width: 0;
                    height: 0;
                    border-left: 8px solid #d3d3d3;
                    border-right: 8px solid transparent;
                    border-bottom: 8px solid transparent;
                    border-top: 8px solid transparent;
                    position: absolute;
                    right: -1.05rem;
                    top: 50%;
                    margin-top: -4px;
                    opacity: 0;
                }

                &::after{
                    content: '';
                    display: block;
                    width: 0;
                    height: 0;
                    border-left: 8px solid #fff;
                    border-right: 8px solid transparent;
                    border-bottom: 8px solid transparent;
                    border-top: 8px solid transparent;
                    position: absolute;
                    right: -1rem;
                    top: 50%;
                    margin-top: -4px;
                    opacity: 0;
                }

                &:hover,
                &.active{
                    border-bottom-color: $colorMain;
                    &::before,
                    &::after{
                        opacity: 1;
                    }

                    a{
                        color: $colorMain;
                    }
                }
            }
        }
    
        @include pad1024{
            margin-left: 1.5em;
            margin-right: 1.5em;
            margin-top: 1em;
            margin-bottom: 2rem;
            ul{
                padding-left: get-vw-mobile(30px);
                margin-right: get-vw-mobile(30px);
                > li{
                    &::before{
                        right: -1.1rem;
                    }
                }
            }
        }
    }

    &-head{
        --min-size: 20;
        --max-size: 30;
        --font-size: calc((var(--min-size) * 1px) + (var(--max-size) - var(--min-size)) * ((100cqw - 320px) / (1920 - 320)));
        font-size: clamp(var(--min-size) * 1px, var(--font-size), var(--max-size) * 1px);

        color: #fff;
        @extend .fw-900;
        @include radius(40px);
        background-color: $colorMain;
        text-align: center;
        line-height: 1;
        padding-top: 0.75em;
        padding-bottom: 0.45em;
    }
}

// -------- OTHER -------- //
.unit{
    position: relative;
    display: block;
    line-height: 1.5;
    text-align: center;
    padding-left: 10px;
    padding-right: 10px;
    .btn{
        padding-top: 0.6em;
        padding-bottom: 0.6em;
    }

    &-icon{
        padding-left: 1.5em !important;
        padding-right: 0;
        .icon{
            margin-top: 0;
            position: absolute;
            left: 0;
            top: -0.1rem;
        }
    }

    @include setMaxRwd{
        text-align: left;
        font-size: 16px;
        &-icon{
            .icon{
                top: 0.85rem;
            }
        }
    }
}

.select_style {
    &::after {
        content: '\F282';
        font-family:'bootstrap-icons' !important;
        display: block;
        @include fontSize(14px);
        position: absolute;
        right: 8px;
        top: 1px;
    }

    select.form-control {
        background-color: #fff;
        border: 1px solid #e6eaea !important;
        text-align: left;
        @include radius(0);
        height: 40px !important;
        min-height: auto;
        padding-top: 0;
        padding-bottom: 0; 
    }

    select {
        width: 100%;
        background: transparent;
        appearance: none;
        -webkit-appearance: none;
    }
    select,
    input {
        border: none !important;
        height: 100% !important;
    }
    .form-control {
        border-radius: 0;
    }
    .disabled {
        background-color: #e9ecef;
        select,
        input {
            background: transparent;
        }
    }
}

// -------- 標題樣式 -------- //
// 內容標題
.title{
    &-wrap{
        text-align: center;
        padding: 10px;
        margin-top: 10px;
        margin-bottom: 10px;

        .btn{
            .icon{
                @include fontSize(23px);
            }
        }

        @include setMaxRwd{
            margin-bottom: 2px;
        }
    }

    // 中英文字
    &-en{
        font-family: $Mitr;
        @include fontSize(35px);
        text-transform: uppercase;
        margin-bottom: 0;
        @extend .fw-900;

        @include pad{
            font-size: get-vw-mobile(40px);
        }
    }

    &-ch{
        position: relative;
        @include fontSize(45px);
        @extend .fw-900;
        color: #595757;

        @include pad{
            font-size: get-vw-mobile(28px);
        }

        @include mobile{
            font-size: get-vw-mobile(48px);
        }
    }

    &-main{
        &Wrap{
            @extend %setFlex-center;
            justify-content: space-between;
            padding-top: 0.25em;
            @include setMaxRwd{
                padding-top: 0.65em;
                padding-bottom: 0.65em;
            }
        }
    }

    &Style{
        position: relative;
        padding: 0.25em;
        &::before {
            content: "";
            display: block;
            @include setSize(8px,8px);
            background-color: $colorItem;
            @include radius(50%);
            position: absolute;
            right: -8px;
            top: 8px;
        }
    }

    // 頂線
    &__topLine{
        border-top-width: 2px;
        border-top-style: solid;
        border-top-color: #000;
    }

    // 底線 : 細
    &__solid{
        border-bottom-width: 1px;
        border-bottom-style: solid;
        border-bottom-color: #b3b3b3;
    }

    // 底線 : 粗
    &__underLine{
        border-bottom-width: 2px;
        border-bottom-style: solid;
        border-bottom-color: #000;
    }

    &-left-circle{
        position: relative;
        padding-left: 30px;
        z-index: 1;
        &::before {
            content: "";
            position: absolute;
            @include setSize(10px,10px);
            border: $colorMain 2px solid;
            border-radius: 50%;
            top: 11px;
            left: 10px;
            z-index: -1;
        }
    }

    &-right-squre{
        position: relative;
        padding-left: 15px;
        z-index: 1;
        &::before {
            content: "";
            @include setSize(15px,15px);
            background-color: #0125fd;
            position: absolute;
            bottom: 2px;
            right: -40px;
            z-index: -1;
        }
    }

    // 左上粗底線
    &__topHead{
        &::before {
            content: '';
            display: block;
            @include setSize(30px,0.5rem);
            background-color: $colorMain;
            margin-bottom: 1rem;
        }
    }
}

// 主標題
.heading-title{
    --min-size: 25;
    --max-size: 38;
    --font-size: calc((var(--min-size) * 1px) + (var(--max-size) - var(--min-size)) * ((100cqw - 320px) / (1920 - 320)));
    font-size: clamp(var(--min-size) * 1px, var(--font-size), var(--max-size) * 1px);

    @extend .fw-900;
    color: #565656;
    font-family: $Josefin;

    &-item{
        --min-size: 16;
        --max-size: 20;
        --font-size: calc((var(--min-size) * 1px) + (var(--max-size) - var(--min-size)) * ((100cqw - 320px) / (1920 - 320)));
        font-size: clamp(var(--min-size) * 1px, var(--font-size), var(--max-size) * 1px);
        display: inline-block;
        border: 2px solid #d6d6d6;
        padding: 0.45em 1em 0.35em;
        margin-top: 0.35em;
        font-family: $fontBase;
        &:before,
        &:after{
            content: '';
            display: block;
            @include setSize(80%,10px);
            background-color: #fff;
            position: absolute;
            left: 13px;
        }
        
        &:before{
            top: -5px;
        }

        &:after{
            bottom: -5px;
        }
    }

    &-wrap{
        width: 100%;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
    }

    // 垂直
}

/* 進階版自適應文字大小 : 主標題
<div class="clamp-container">
    <h4 class="clamp-title-20to35">會員資料</h4>
</div> */

.clamp{
    &-container{
        container-type: inline-size;

        &-main{
            &::before{
                content: '';
                display: block;
                @include setSize(100%,1px);
                background-color: #bfbfbf;
                position: absolute;
                bottom: 0;
            }
        }

        @include setMaxRwd{
            margin-top: 1.5em;
            margin-bottom: 1.5em;
        }
    }

    &-ImgStyle{
        &::after{
            content: '';
            display: block;
            @include setSize(100%,244px);
            max-width: get-vw(253px);
            @include setBackSet(top,get-vw(253px));
            background-image: url('../images/img-outline-rhombic.png');

            position: absolute;
            right: get-vw(10px);
            top: 50%;
            margin-top: -60px;
            z-index: -1;

            @include setMaxRwd{
                opacity: 0.5;
                @include setSize(100%,130px);
                max-width: 100%;
                background-size: cover;
            }
        }
    }
    
    &-title-20to30{
        --min-size: 20;
        --max-size: 30;
        --font-size: calc((var(--min-size) * 1px) + (var(--max-size) - var(--min-size)) * ((100cqw - 320px) / (1920 - 320)));
        font-size: clamp(var(--min-size) * 1px, var(--font-size), var(--max-size) * 1px);
    }

    &-title-submain{
        --min-size: 18;
        --max-size: 24;
        --font-size: calc((var(--min-size) * 1px) + (var(--max-size) - var(--min-size)) * ((100cqw - 320px) / (1920 - 320)));
        font-size: clamp(var(--min-size) * 1px, var(--font-size), var(--max-size) * 1px);
        &.ch{

        }
    }

    &-title-main{
        --min-size: 20;
        --max-size: 130;
        --font-size: calc((var(--min-size) * 1px) + (var(--max-size) - var(--min-size)) * ((100cqw - 320px) / (1920 - 320)));
        font-size: clamp(var(--min-size) * 1px, var(--font-size), var(--max-size) * 1px);
        
        margin-top: 12.5px;
        padding-bottom: 20px;
        &.en{
            font-family: "good-times", sans-serif;
            color: #d9dadb;
        }
    }
}

// 麵包屑
.breadcrum-wrap {
    margin-top: get-vw(15px);

    @include setMaxRwd{
        margin-top: get-vw-mobile(15px);
        margin-bottom: get-vw-mobile(15px);
    }

    .breadcrumb {
        @include fontSize(16px);
        color: #404040;
        background-color: transparent;
        margin-bottom: 0;

        .breadcrumb-item{
            color: #777;
            &+.breadcrumb-item{
                &::before {
                    content: "\F285";
                    font-family: "bootstrap-icons" !important;
                    padding-right: 0.65em;
                }
            }
            
            &.active{
                color: $colorBlack;
            }
        }

        @include setMinRwd{
            justify-content: flex-end;
            padding-top: 0.75rem;
        }

        @include setMaxRwd{
            padding-left: 0;
            padding-top: 0.3em;
            padding-bottom: 0.75rem;
        }
    }

    .icon{
        margin-top: 0px;
        margin-right: 2px;
        img{
            vertical-align: initial
        }
    }
}

// 頁碼
.pagination-wrap {
    @extend %setFlex;
    justify-content: center;
    margin-top: 2rem;
    margin-bottom: 1rem;
    .pagination {
        justify-content: center;
        .page-link {
            @include setSize(33px,33px);
            line-height: 33px;
            color: #959696;
            background-color: transparent;
            border-color: transparent;
            @include radius(50%);
            text-align: center;
            padding: 0;
            margin-right: 0.5rem;

            &:hover,
            &.active {
                color: #fff;
                background-color:$colorMain;
                border-color:$colorMain;
            }
        }
    }

    .pagination-select{
        display: flex;
        align-items: center;
        select{
            text-align: center;  
            appearance: auto;
            @include fontSize(15px);
        }

        .pagination-item{
            padding-left: 15px;
        }
    }

    .pagination-mobile{
        max-width: 120px;
    }

    @include setMinRwd{
        .pagination-desktop{}

        .pagination-mobile{
            .pagination-select{
                width: auto;
                padding-left: 5px;
                select{
                    max-width: 45px;
                }
            }
        }
    }

    @include setMaxRwd{
        margin-top: 0.5rem;
        margin-bottom: 2rem;
    }
}


//-------- 文字 --------//
// 顏色
$textColor-colors: (Main: #064583,Link: #1d2088,Normal: #5d5d5e, Base: $colorBase, itemSub: $colorSubText, item: $colorItem, abbr: #fd5604);

.text{
    &-item{
        color: map-get($textColor-colors , item) !important;
    }

    &-abbr{
        color: map-get($textColor-colors , abbr) !important;
    }

    // 樣式
    &-underLine{
        text-decoration: underline !important;
    }

    &-uppercase{
        text-transform: uppercase;
    }

    &-italic{
        font-style: italic;
    }
}

// 文字粗體
$text-bold: (100: 100, 200: 200, 300: 300, 400: 400, 600: 600, 700: 700, 900: 900);
.fw{
    &-100 {
        font-weight: map-get($text-bold , 100) !important;
    }
    
    &-200 {
        font-weight: map-get($text-bold , 200) !important;
    }
    
    &-300 {
        font-weight: map-get($text-bold , 300) !important;
    }
    
    &-400 {
        font-weight: map-get($text-bold , 400) !important;
    }
    
    &-600 {
        font-weight: map-get($text-bold , 600) !important;
    }
    
    &-700 {
        font-weight: map-get($text-bold , 700) !important;
    }
    
    &-900 {
        font-weight: map-get($text-bold , 900) !important;
    }
}


//-------- 區塊色框 --------//
// 顏色
$bgColor-colors: (Main: #064583,Link: #1d2088,Normal: #5d5d5e, Base: $colorBase, itemSub: #6f6e6e);

.bg{
    &-itemSub{
        background-color: map-get($bgColor-colors , itemSub) !important;
    }
}


// 左右對齊
.justify{
    text-align: justify;
}

//-------- 頁面使用 : 自訂 --------//
.flex{
    &-none{
        flex: none !important;

        @include mobile{
            width: 100%;
        }
    }

    &-auto{
        flex: auto !important
    }

    &-1{
        flex: 1 !important
    }
}


//-------- 頁面使用 : Grid --------//
.page{
    &-wrapper{
        margin-top: 50px;
        padding-bottom: 70px;

        @include setMaxRwd{
            margin-top: 1em;
            margin-bottom: 1em;
            padding-bottom: 1em;
        }
    }

    &Grid{
        display: grid;
        grid-column-gap: 0;
        grid-auto-rows: 0fr 0fr 0fr;

        &-list{
            &-2to1{
                grid-template-columns: 2fr 1fr 1fr;
            }
            
            &-avg{
                grid-template-columns: 1fr 1fr 1fr 1fr;
            }

            &-1to2{
                grid-template-columns: 1fr 1fr 2fr;
            }

            &-averageTo50p{
                grid-template-columns: repeat(2, 50%);
                // @include setWidthRwd(1100px,1400px){
                //     grid-template-columns: repeat(2, auto);
                // }
            }

            &-averageToAuto-2{
                grid-template-columns: repeat(2, auto);
            }
        }

        @include pad1080{
            grid-template-columns: auto !important;
        }

        @include setMaxRwd{
            display: block;
        }
    }
}

// 項目
.tag-item {
    display: inline-block;
    width: auto;
    line-height: 1.1;
    margin: 2.5px;
    font-size: 14px;
    line-height: 1.5;
    padding: 6px;
    text-align: center;
    background-color: $bgSecondary;
    color: $colorText; 

    &-outline-main{
        @extend .tag-item;
        @include radius(30px);
        color: $colorMain;
        border: $colorMain 1px solid;
        background-color: transparent;
      
        --min-size: 16;
        --max-size: 18;
        --font-size: calc((var(--min-size) * 1px) + (var(--max-size) - var(--min-size)) * ((100cqw - 320px) / (1920 - 320)));
        font-size: clamp(var(--min-size) * 1px, var(--font-size), var(--max-size) * 1px);
        padding: 0.75rem 1.35rem 0.3rem;
    }
}

.radio{
    &_style {
        @extend %setFlex-center;
        margin-top: 10px !important;
        input {
            &[type="radio"] {
                display: none;
            }
            &+label {
                position: relative;
                display: flex;
                align-items: center;
                line-height: 1;
                padding-left: 30px;
                margin-bottom: 0;
                &::before,
                &::after {
                    content: "";
                    display: inline-block;
                    border-radius: 50%;
                }
                
                &::before {
                    width: 20px;
                    height: 20px;
                    border: #cecece 1px solid;
                    background: #fff;

                    position: absolute;
                    top: -1px;
                    left: 0;
                }

                &::after {
                    width: 10px;
                    height: 10px;
                    background-color: transparent;
                    position: absolute;
                    left: 5px;
                    top: 4px;
                }
            }
            &:checked {
                &+label {
                    &::after {
                        background-color: $colorMain;
                    }
                }
            }
        }
    }
}

// 
.error-row{
    background: url('../images/error-background.svg') no-repeat;
    background-position: center top;
    max-width: 1400px;
    margin-left: auto;
    margin-right: auto;
    padding: 20px;
    .img-box{
        max-width: 640px;
        margin-left: auto;
        margin-right: auto;
        position: relative;
        width: 100%;
        img{
            display: block;
            width: 100%;
        }
        .error-code{
            color: #555;
            font-size: 120px;
            font-weight: bold;
            letter-spacing: 5px;
            line-height: 1;
            margin: 0;
            position: absolute;
            top: 42%;
            left: 50%;
            transform: translateX(-57%);
        }
    }
    .content{
        margin-top: 50px;
        text-align: center;
        .title{
            color: #444;
            font-size: 30px;
            font-weight: bold;
            margin: 0;
        }
        .des{
            margin-top: 25px;
            p{
                color: #555;
                font-size: 18px;
                line-height: 1.6;
                margin: 0;
                &:not(:last-child){
                    margin-bottom: 5px;
                }
            }
        }
    }
    .btn-row{
        margin-top: 40px;
        margin-bottom: 20px;
        .link{
            border-radius: 30px;
            background: #666666;
            color: #fff;
            display: block;
            font-size: 24px;
            padding: 15px 13px 8px;
            margin-left: auto;
            margin-right: auto;
            text-align: center;
            text-decoration: inherit;
            text-transform: uppercase;
            transition: all .4s;
            width: 240px;
            &:hover{
                background: #333;
            }
        }
    }
}

@media (max-width:1440px){
    .error-row {
        .img-box{
            max-width: 540px;
            .error-code{
                font-size: 100px;
            }
        }
        .btn-row{
            margin-top: 50px;
        }
    }
}

@media (max-width:1024px){
    .error-row {
        .img-box{
            max-width: 440px;
            .error-code{
                font-size: 70px;
                top: 45%;
            }
        }
        .btn-row{
            margin-top: 50px;
        }
    }
}

@media (max-width:640px){
    .error-row {
        .img-box{
            .error-code{
                font-size: 50px;
                top: 45%;
            }
        }
        .content{
            margin-top: 30px;
            .title{
                font-size: 26px;
            }
            .des{
                margin-top: 15px;
                p{
                    font-size: 16px;
                }
            }
        }
        .btn-row{
            margin-top: 30px;
            .link{
                font-size: 17px;
                max-width: 170px;
            }
        }
    }
}