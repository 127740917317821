// for Index swiper effect
$color_1: #fff0;
// $font-family_1: var(--fancy-slider-font-family);

:root {
    --fancy-slider-theme-btn:#1b7cb9;
    --fancy-slider-theme-color: #fff;
    --fancy-slider-font-family: sans-serif;
    
    --fancy-slider-button-side-offset: 32px;
    --fancy-slider-button-mobile-side-offset: 12px;
    --fancy-slider-button-mobile-bottom-offset: 0px;

    --fancy-slider-head-font-size-s: 36px;
    --fancy-slider-head-font-size: 48px;
    --fancy-slider-head-font-size-b: 60px;
    --fancy-slider-title-font-size: 33px;
    
    --fancy-slider-title-font-family: "good-times", sans-serif;
    --fancy-slider-title-line-height: 1;
    
    /* background */
    --fancy-slider-transition-background-duration: 1000ms; /* duration background color */
    --fancy-slider-transition-background-delay: 1000ms;    /* delay background color */
    
    /* other */
    --fancy-slider-transition-slide-delay: 500ms; /* delay slide translation */
    --fancy-slider-transition-title-delay: 850ms; /* delay title translation */
    --fancy-slider-transition-timing-function: cubic-bezier(0.5, 0, 0, 1); /* global timing function */
    
    /* scaling */
    --fancy-slider-transform-scale-slide: 0.6;               /* before scale in slide - same as in js */
    --fancy-slider-transform-scale-img: 1.2;               /* before scale in img - same as in js */
    
    /* nav */
    --fancy-slider-transition-button: 400ms;        /*  */
    --fancy-slider-transition-button-arrow: 400ms;  /*  */
    --fancy-slider-transition-button-circle: 400ms; /*  */
}
    
@keyframes jump {
    0% {
        bottom: 0;
    }
    50% {
        bottom: 10px;
    }
    100% {
        bottom: 0;
    }
}

@keyframes moveCircle {
    0% {
        transform: translateY(0%);
    }

    50% {
        transform: translateY(-5%);
    }

    100% {
        transform: translateY(0%);
    }
}
  
@keyframes opacity {
    0% {
        opacity: 0;
    }
    50% {
        opacity: 100%;
    }
    100% {
        opacity: 0;
    }
}

.index{
    // 內框
    &-content{
        padding-top: 1.5em;
        padding-bottom: 2em;

        .heading-title-wrap{
            margin-top: 0.25em;
            margin-bottom: get-vw(50px);
        }

        @include setStyleRwd(1400px){
            padding-left: 1em;
            padding-right: 1em;
        }

        @include setMaxRwd{
            padding: 1.5em 0.5em;
            .heading-title-wrap{
                margin-top: 1em;
                margin-bottom: 1em;
            }
        }
    }

    // 頁面使用
    &-page{
        &Wrapper{
            @extend %setFlex;
            @include setMaxRwd{
                width: 100%;
                justify-content: center;
            }
        }

        // 裝飾 : 圓圈圈
        &Circle{
            &-wrap{
                top: calc(-365px / 2.5);
            }
        }

        // 研發動態
        &Develop{
            padding-top: 0.25em;
            padding-bottom: get-vw(120px);
            &-wrap{
                
            }

            .index-card-wrap{
                margin-top: get-vw(50px);

                @include setMinRwd{
                    .card-body{
                        padding-bottom: 4em;
                    }
                }
            }
        }

        // 研發文訊
        &Digest{
            position: relative;
            z-index: 0;
            .card{
                margin-left: 0;
                margin-right: 0;
                flex: 0 0 100% !important;
                max-width: 100% !important
            }

            @include setMinRwd{
                &-headWrap{
                    position: absolute;
                    left: 0;
                    width: auto;
                    .heading-title{
                        transform: translateY(380px) rotate(90deg);
                        position: relative;
                        &-item{
                            padding-top: 0.45em;
                            transform: translate(60px,250px) rotate(90deg);
                            position: relative;
                            span{
                                writing-mode:vertical-lr;
                                position: relative;
                                transform: rotate(-90deg);
                            }
                        }
                    }
                }
            }

            @include setWidthRwd(1101px,1500px){
                &-headWrap{
                    left: -1em;
                }
            }

            &-swiper{
                @include setMinRwd{
                    width: 100%;
                    max-width: calc(100% - 6.25rem);
                    margin-left: auto;
                    margin-right: get-vw(-200px);
                }
            }

            .swiper{
                &-pagination{
                    bottom: -1.5em !important;
                    &-bullet{
                        &-active{
                            background-color: $colorMain;
                        }
                    }
                }
            }
        }

        // 人物專訪
        &Interview{
            &-wrap{
               .card{
                    box-shadow: none;
                    border: none;
                    align-items: flex-start;
                    overflow: visible;
                    justify-content: space-between;
                    background-color: transparent;
                    flex: 0 0 100%;
                    max-width: 100%;
                    padding-top: 1em;

                    &-img-top{
                        border-radius: 0;
                        border-top-left-radius: 80px;
                        border-bottom-right-radius: 80px;
                        overflow: hidden;
                    }
                    
                    &-body{
                        padding: 1.5em;
                    }

                    &-title{
                        --min-size: 20;
                        --max-size: 30;
                        --font-size: calc((var(--min-size) * 1px) + (var(--max-size) - var(--min-size)) * ((100cqw - 320px) / (1920 - 320)));
                        font-size: clamp(var(--min-size) * 1px, var(--font-size), var(--max-size) * 1px);
                        margin-bottom: 0.5em;
                    }

                    &-des{
                        line-height: 1.75;
                    }

                    @include setMinRwd{
                        flex-direction: row;
                        padding-top: 5em;
                        &-img-top{
                            max-width: 630px;
                            position: absolute;
                            z-index: 1;
                            top: 0;
                        }
                        &-body{
                            padding: 5.5em 6em 3em 4em;
                            padding-left: 32em;
                            
                            max-width: calc(100% - 7em);
                            margin-left: auto;
                        }
                    }

                    @include setWidthRwd(1101px,1500px){
                        &-img-top{
                            max-width: 25em;
                        }

                        &-body{
                            padding-left: 20em;
                        }
                    }
                } 

                &::before{
                    content: url('../images/img-circleHalf-01.png');
                    position: absolute;
                    right: 1em;
                    z-index: 1;
                    @include setMaxRwd{
                        right: 0;
                    }
                }

                &::after{
                    content: url('');
                    width: 100%;
                    height: calc(50% - 4em);
                    background-color: #e1e7ef;
                    border-radius: 80px;
                    position: absolute;
                    z-index: 0;
                    @include setMinRwd{
                        width: calc(100% - 220px);
                        height: calc(100% - 13em);
                        left: 200px;
                    }
                }

                &::after,
                &::before{
                    top: 50%;
                    @include setMinRwd{
                        top: 13em;
                    }
                }
            }

            .pageCircle {
                right: -100px;
                left: auto;
            }

            .index-pageCircle {
                &-wrap {
                    top: 0;
                }
            }

            &-swiper{
                padding-top: 6em;
                overflow: hidden;
            }

            .swiper{
                &-button-lock,
                &-pagination-lock{
                    display: block;
                }

                &-el-wrapper{
                    display: flex;
                    align-items: baseline;
                    justify-content: space-around;
                    .swiper-pagination{
                        width: auto;
                        position: static;
                        color: #777;
                        &-current,
                        &-total{
                            font-family: Arial;
                            padding-left: 5px;
                            padding-right: 5px;
                        }
                        
                        &-current{
                            color:$colorMain;
                            font-size: 1.5em;
                        }
                    }
                    .swiper-button-prev,
                    .swiper-button-next{
                        position: static;
                        color: $colorMain;
                        @extend .fw-900;
                        &::after{
                            font-size: 1.25em;
                        }
                    }

                    @include setMinRwd{
                        min-width: 10em;
                        position: absolute;
                        right: 8em;
                        bottom: 2em;
                    }
                }
            }

            @include setMinRwd{
                padding-top: get-vw(120px);
                padding-bottom: get-vw(120px);
                &::before,
                &::after{
                    content: '';
                    display: block;
                    @include setSize(45px,45px);
                    background-color: #000;
                    @include radius(50%);
                    position: absolute;
                }

                &::before{
                    left: 16.5%;
                    background-color: #e0d6fd;
                }

                &::after{
                    left: 40%;
                    bottom: 1%;
                    background-color: #e4f1f8;
                }

                &-headWrap{
                    max-width: 27em;
                    align-items: flex-start;
                    position: absolute;
                    top: 6em;
                    right: calc(50% - 375px - 1.75em);
                    z-index: 1;
                }
            }

            @include setWidthRwd(1401px,1500px){
                &-headWrap{
                    right: calc(50% - 325px + 4.5rem);
                }
            }

            @include setWidthRwd(1301px,1400px){
                &-headWrap{
                    right: calc(50% - 325px + 2.5rem);
                }
            }

            @include setWidthRwd(1101px,1300px){
                &-headWrap{
                    right: calc(50% - 325px - 0.5rem);
                }
            }
        }
    }
}

// 共用
.page{
    // Banner形象圖
    &-banner{
        width: 100%;
        overflow: hidden;
        z-index: 1;

        @include setWidthRwd(900px,1100px){
            margin-top: 1%;
        }

        &.index{
            @include setBackSet(top,auto);
            background-image: url('../images/index-banner-bg.jpg');

            .fancy-slider-container{
                display: flex;
                align-items: center;
            }

            @include setMinRwd{
                .fancy-slider-container{
                    padding-top: 3.5em;
                    padding-bottom: 12em;
                    max-width: 1480px;
                }
                .fancy-slider-scale{
                    position: relative;
                    flex: 0 0 880px;
                }
            }

            @include setWidthRwd(1101px,1500px){
                background-size: 97em;
                .fancy-slider-container{
                    max-width: 75em;
                    padding-left: 2em;
                    padding-right: 2em;
                }
                .fancy-slider-scale{
                    flex: 0 0 40em;
                }
            }

            @include setWidthRwd(1101px, 1300px){
                background-size: 86em;
                .fancy-slider-scale{
                    flex: 0 0 33em;
                }
            }

            @include setMaxRwd{
               min-height: get-vw-mobile(300px);
               padding: 0.75em 1em 1.5em;
               background-size: cover;
               margin-bottom: 2em;

               .fancy-slider-container{
                flex-direction: column;
               }

               .fancy-slider-scale{
                order: -1;
                margin-bottom: 2.5em;
               }
            }
        }

        &.main{
            @include mobile{
                img{
                    height: 125px;
                    object-fit: cover;
                }
            }
        }
    }

    &-scrollDown{
        position: absolute;
        height: 75px;
        margin: 2em;
        bottom: 5.5em;
        z-index: 200;
        left: 50%;
        &-btn {
            position: absolute;
            @include setSize(120px,72px);
            text-align: center;
            left: 50%;
            transform: translateX(-50%);
            animation: jump 1.5s infinite;
        }
    
        @include mobile{
            display: none;
        }
    }

    // 裝飾 : 圓圈圈
    &Circle{
        position: absolute;
        left: 150px;
        top: 0;
        animation: moveCircle 1.5s infinite;

        &-wrap{
            top: calc(-365px / 2.5);
        }

        @include setWidthRwd(1101px,1500px){
            left: 4em;
        }

        @include setMaxRwd{
            max-width: 10em;
            left: 0;
            z-index: -1;
        }
    }
}

/* swiper */
.fancy-slider {
	display: flex;
	align-items: center;
	justify-content: center;
	width: 100%;
	height: 100%;
	--fancy-slider-title-font-size: 88px;
	.swiper {
		width: 100%;
		height: 100%;
		transition-duration: var(--fancy-slider-transition-background-duration);
		transition-property: background-color;
		transition-delay: var(--fancy-slider-transition-background-delay);
	}

	.swiper-wrapper {
		transition-delay: var(--fancy-slider-transition-slide-delay);
		transition-timing-function: var(--fancy-slider-transition-timing-function);
	}
	
    .swiper-slide {
		text-align: center;
		display: flex;
		justify-content: center;
		align-items: center;
	}
}

.fancy-slider-no-transition {
	transition-duration: 0ms !important;
	transition-delay: 0ms !important;
	transition-property: none !important;
	* {
		&:not(.swiper-wrapper) {
			transition-duration: 0ms !important;
			transition-delay: 0ms !important;
			transition-property: none !important;
		}
	}
	.swiper-wrapper {
		transition-delay: 0ms !important;
	}
}

.fancy-slider-title {
	transition-delay: var(--fancy-slider-transition-title-delay);
	z-index: 10;

    &-img{
        position: absolute;
        right: -14.5vw;
        z-index: -1;
        top: -1.5vw;
        opacity: 0;
        img{
            transform: scale(1) !important;
        }
        @include setMinRwd{
            width: get-vw(940px);
        }

        @include mobile{
            display: none;
        }
    }
}

.fancy-slider-scale {
	img {
		width: 100%;
		height: 100%;
		object-fit: cover;
	}

    @include setMinRwd{
        width: 100%;
        height: 100%;
        position: absolute;
        opacity: 0.9;
        overflow: hidden;
    }
}

.fancy-slider-button {
	transition: var(--fancy-slider-transition-button);
	outline: none;
	position: absolute;
	width: 140px;
	z-index: 10;
	top: 50%;
    transform: translateY(-50%);
	cursor: pointer;

    @include setMinRwd{
        top: 65%;
    }

    @include setMaxRwd{
        width: 100px;
    }

	svg {
		display: block;
		transition: var(--fancy-slider-transition-button);
	}
	.fancy-slider-svg-circle-wrap {
		transition: var(--fancy-slider-transition-button-circle);
		transform-origin: -20px 40px;
		opacity: 1;
	}
	circle {
		transition: var(--fancy-slider-transition-button-circle);
		stroke-width: 2px;
		stroke: #fff;
		fill: none;
		stroke-dasharray: 1;
		stroke-dashoffset: 1;
		opacity: 1;
		transform-origin: 0px 0px 0px;
	}
	.fancy-slider-svg-arrow {
		transition: var(--fancy-slider-transition-button-arrow);
		fill: #fff;
        transform: rotateY(180deg) translate(-45px, 30.5px) scale(3);
	}
	.fancy-slider-svg-wrap {
		transform: translateY(353px);
	}
	.fancy-slider-svg-line {
		transition: var(--fancy-slider-transition-button);
		stroke: #fff;
		stroke-width: 2;
		transform: translate(50px, 42px);
	}
	&:not(.fancy-slider-button-disabled) {
		&:hover {
			circle {
				stroke-dasharray: 4px;
				stroke-dashoffset: 4px;
				opacity: 1;
			}
		}
	}
}

.fancy-slider-button-prev {
	left: var(--fancy-slider-button-side-offset);
	&:not(.fancy-slider-button-disabled) {
		&:hover {
			svg {
				transform: translate(-16px);
			}
		}
	}
}

.fancy-slider-button-next {
	right: var(--fancy-slider-button-side-offset);
	.fancy-slider-svg-wrap {
		transform: translateY(353px) rotateY(180deg);
		transform-origin: 80px 0px 0px;
	}
	&:not(.fancy-slider-button-disabled) {
		&:hover {
			svg {
				transform: translate(16px);
			}
		}
	}
}

.fancy-slider-button-disabled {
	opacity: 0.2;
	cursor: default;
}

.fancy-slider{
    .swiper {
        margin-left: auto;
        margin-right: auto;
        position: relative;
        overflow: hidden;
        list-style: none;
        padding: 0;
        z-index: 1;
    }

    .swiper-vertical {
        >.swiper-wrapper {
            flex-direction: column;
        }
    }

    .swiper-wrapper {
        position: relative;
        width: 100%;
        height: 100%;
        z-index: 1;
        display: flex;
        transition-property: transform;
        box-sizing: content-box;
        transform: translate(0);
    }

    .swiper-android {
        .swiper-slide {
            transform: translate(0);
        }
    }

    .swiper-pointer-events {
        touch-action: pan-y;
    }

    .swiper-pointer-events.swiper-vertical {
        touch-action: pan-x;
    }

    .swiper-slide {
        flex-shrink: 0;
        width: 100%;
        height: 100%;
        position: relative;
        transition-property: transform;
    }

    .swiper-slide-invisible-blank {
        visibility: hidden;
    }

    .swiper-autoheight {
        height: auto;
        .swiper-slide {
            height: auto;
        }
        .swiper-wrapper {
            align-items: flex-start;
            transition-property: transform, height;
        }
    }

    .swiper-backface-hidden {
        .swiper-slide {
            transform: translateZ(0);
            backface-visibility: hidden;
        }
    }

    .swiper-3d {
        perspective: 1200px;
        .swiper-wrapper {
            transform-style: preserve-3d;
        }
        .swiper-slide {
            transform-style: preserve-3d;
        }
        .swiper-slide-shadow {
            transform-style: preserve-3d;
            position: absolute;
            left: 0;
            top: 0;
            width: 100%;
            height: 100%;
            pointer-events: none;
            z-index: 10;
            background: rgba(0, 0, 0, 0.15);
        }
        .swiper-slide-shadow-left {
            transform-style: preserve-3d;
            position: absolute;
            left: 0;
            top: 0;
            width: 100%;
            height: 100%;
            pointer-events: none;
            z-index: 10;
            background-image: linear-gradient( to left, rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0) );
        }
        .swiper-slide-shadow-right {
            transform-style: preserve-3d;
            position: absolute;
            left: 0;
            top: 0;
            width: 100%;
            height: 100%;
            pointer-events: none;
            z-index: 10;
            background-image: linear-gradient( to right, rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0) );
        }
        .swiper-slide-shadow-top {
            transform-style: preserve-3d;
            position: absolute;
            left: 0;
            top: 0;
            width: 100%;
            height: 100%;
            pointer-events: none;
            z-index: 10;
            background-image: linear-gradient( to top, rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0) );
        }
        .swiper-slide-shadow-bottom {
            transform-style: preserve-3d;
            position: absolute;
            left: 0;
            top: 0;
            width: 100%;
            height: 100%;
            pointer-events: none;
            z-index: 10;
            background-image: linear-gradient( to bottom, rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0) );
        }
        .swiper-cube-shadow {
            transform-style: preserve-3d;
        }
    }

    .swiper-3d.swiper-css-mode {
        .swiper-wrapper {
            perspective: 1200px;
        }
    }

    .swiper-css-mode {
        >.swiper-wrapper {
            overflow: auto;
            scrollbar-width: none;
            -ms-overflow-style: none;
            &::-webkit-scrollbar {
                display: none;
            }
            >.swiper-slide {
                scroll-snap-align: start start;
            }
        }
    }

    .swiper-horizontal.swiper-css-mode {
        >.swiper-wrapper {
            scroll-snap-type: x mandatory;
        }
    }

    .swiper-vertical.swiper-css-mode {
        >.swiper-wrapper {
            scroll-snap-type: y mandatory;
        }
    }

    .swiper-centered {
        >.swiper-wrapper {
            &:before {
                content: "";
                flex-shrink: 0;
                order: 9999;
            }
            >.swiper-slide {
                scroll-snap-align: center center;
            }
        }
    }

    .swiper-centered.swiper-horizontal {
        >.swiper-wrapper {
            >.swiper-slide {
                &:first-child {
                    margin-inline-start: var(--swiper-centered-offset-before);
                }
            }
            &:before {
                height: 100%;
                width: var(--swiper-centered-offset-after);
            }
        }
    }

    .swiper-centered.swiper-vertical {
        >.swiper-wrapper {
            >.swiper-slide {
                &:first-child {
                    margin-block-start: var(--swiper-centered-offset-before);
                }
            }
            &:before {
                width: 100%;
                height: var(--swiper-centered-offset-after);
            }
        }
    }

    @media (max-width: 480px) {
        .fancy-slider {
            --fancy-slider-title-font-size: 60px;
        }
    }

    @media (max-width: 640px) {
        .fancy-slider-button {
            top: auto;
            bottom: var(--fancy-slider-button-mobile-bottom-offset);
            transform: none;
        }
        .fancy-slider-button-prev {
            left: var(--fancy-slider-button-mobile-side-offset);
        }
        .fancy-slider-button-next {
            right: var(--fancy-slider-button-mobile-side-offset);
        }
    }
}

// Banner 文字
.fancy-intro{
    &-wrap,
    &-head{
        color: #fff;
    }

    &-wrap{
        text-align: left;
        @include setMinRwd{
            padding-right: get-vw(40px);
        }
    }

    &-head{
        --min-size: 22.5;
        --max-size: 45;
        --font-size: calc((var(--min-size) * 1px) + (var(--max-size) - var(--min-size)) * ((100cqw - 320px) / (1920 - 320)));
        font-size: clamp(var(--min-size) * 1px, var(--font-size), var(--max-size) * 1px);
    }

    &-title{
        --min-size: 18;
        --max-size: 30;
        --font-size: calc((var(--min-size) * 1px) + (var(--max-size) - var(--min-size)) * ((100cqw - 320px) / (1920 - 320)));
        font-size: clamp(var(--min-size) * 1px, var(--font-size), var(--max-size) * 1px);
        margin-top: 1.15rem;
        margin-bottom: 1.15rem;
        @include setMaxRwd{
            margin-top: 0.75rem;
            margin-bottom: 0.75rem;
        }
    }

    &-des{
        --min-size: 16;
        --max-size: 20;
        --font-size: calc((var(--min-size) * 1px) + (var(--max-size) - var(--min-size)) * ((100cqw - 320px) / (1920 - 320)));
        font-size: clamp(var(--min-size) * 1px, var(--font-size), var(--max-size) * 1px);
        line-height: 1.45;
    }

    &-btn{
        .btn{
            color: #fff !important;
            border-color: var(--fancy-slider-theme-btn) !important;
            background-color: var(--fancy-slider-theme-btn);
            padding-top: 0.5em !important;
            .icon {
                margin-top: -2px;
            }
        }

        @include setMinRwd{
            margin-top: 2.75rem;
        }

        @include setMaxRwd{
            margin-bottom: 5rem;
        }
    }
}

// 
.wave-wrap{
    position: absolute;
    width: 100%;
    bottom: 0;
    left: 0;
    z-index: 1;

    @include setMinRwd{
        bottom: 3.5em;
    }
}

.waves {
  position:relative;
  width: 100%;
  height:15vh;
  margin-bottom:-7px; /*Fix for safari gap*/
  min-height:100px;
  max-height:150px;
}

/* Animation */
.parallax > use {
    animation: move-forever 25s cubic-bezier(.55,.5,.45,.5)     infinite;
}

.parallax > use:nth-child(1) {
    animation-delay: -2s;
    animation-duration: 7s;
}

.parallax > use:nth-child(2) {
    animation-delay: -3s;
    animation-duration: 10s;
}

.parallax > use:nth-child(3) {
    animation-delay: -4s;
    animation-duration: 13s;
}

.parallax > use:nth-child(4) {
    animation-delay: -5s;
    animation-duration: 20s;
}

// @keyframes move-forever {
//     0% {
//         transform: translate3d(-90px,0,0);
//     }
//     100% {
//         transform: translate3d(85px,0,0);
//     }
// }

/*Shrinking for mobile*/
@media (max-width: 768px) {
    .waves {
        height:40px;
        min-height:40px;
    }
}

