@charset "UTF-8";
@import url("//fonts.googleapis.com/css2?family=Noto+Sans+TC&display=swap");
@import url("//fonts.googleapis.com/css2?family=Noto+Sans+TC:wght@900&display=swap");
@import url("//fonts.googleapis.com/css2?family=Roboto&display=swap");
@import url("//fonts.googleapis.com/css2?family=Open+Sans:wght@300&display=swap");
@import url("//fonts.googleapis.com/css2?family=Alfa+Slab+One&display=swap");
@import url("//fonts.googleapis.com/css2?family=Kanit:wght@700&display=swap");
@import url("//fonts.googleapis.com/css2?family=Mitr&display=swap");
@import url("//fonts.googleapis.com/css2?family=Poppins&display=swap");
@import url("//fonts.googleapis.com/css2?family=Poppins:wght@100&display=swap");
@import url("//fonts.googleapis.com/css2?family=Arimo&display=swap");
@import url("//fonts.googleapis.com/css2?family=Montserrat:wght@200&display=swap");
@import url("//fonts.googleapis.com/css2?family=Work+Sans&display=swap");
@import url("//fonts.googleapis.com/css2?family=Josefin+Sans:wght@700&display=swap");
a, body {
  color: #222222;
  font-family: "Josefin Sans", sans-serif, "Noto Sans TC", "Microsoft JhengHei", "微軟正黑體", "Arial", sans-serif;
  line-height: 1.35;
  font-weight: 400;
  --min-size: 16;
  --max-size: 18;
  --font-size: calc((var(--min-size) * 1px) + (var(--max-size) - var(--min-size)) * ((100cqw - 320px) / (1920 - 320)));
  font-size: clamp(var(--min-size) * 1px, var(--font-size), var(--max-size) * 1px);
}

.sprite, input, form, body, html {
  margin: 0px;
  padding: 0px;
}

.form-flex, .index-pageWrapper, .card-wrap, .card-item-wrap, .box, .pagination-wrap, .mainContent {
  display: flex;
  flex-wrap: wrap;
}

.otherarticle, .article-onesetin, .card-time, .btn-wrap, .radio_style, .title-mainWrap, footer .footer-info-wrap {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
}

.mainContent-list .card-img-top img, .imgCenter-4-3 img {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  max-height: 100%;
  margin: auto;
}

.font-size-1 {
  font-size: 87.5% !important;
}

.font-size-2 {
  font-size: 100% !important;
}

.font-size-3 {
  font-size: 112.5% !important;
}

.font-size-4 {
  font-size: 150% !important;
}

.font-size-5 {
  font-size: 175.5% !important;
}

.font-size-6 {
  font-size: 250% !important;
}

.font-size-7 {
  font-size: 275% !important;
}

.font-size-8 {
  font-size: 362.5% !important;
}

.font-size-9 {
  font-size: 450% !important;
}

.line-height-1 {
  line-height: 1 !important;
}

.line-height-2 {
  line-height: 1.25 !important;
}

.line-height-3 {
  line-height: 1.5 !important;
}

.line-height-4 {
  line-height: 1.75 !important;
}

.line-height-5 {
  line-height: 2 !important;
}

.noJS > div,
.noJS > ul {
  display: none;
}

.noJS > noscript {
  display: block;
  width: 100%;
  line-height: 10em;
}

* {
  box-sizing: border-box;
}

html {
  overflow: -moz-scrollbars-vertical;
}

body {
  background: #fff;
  visibility: visible;
  overflow-x: hidden;
}
body.fixed {
  overflow: hidden;
}

div {
  position: relative;
}

ol,
ul {
  list-style: none;
  margin: 0;
  padding-left: 0;
}

.sh_sans {
  font-family: "source-han-sans-traditional", "微軟正黑體", sans-serif;
}

.sh_serif {
  font-family: "source-han-serif-tc", serif;
}

form {
  font-size: 16px;
}

label {
  display: inline-flex;
  align-items: center;
  margin-right: 12px;
  cursor: pointer;
}

input {
  -webkit-autofill: unset;
  font-size: 16px;
  background-color: #eeeeee;
}
input:-webkit-autofill {
  -webkit-box-shadow: 0 0 0 30px #fcfcfc inset;
}
input:focus {
  outline: 0;
}
input[type=text], input[type=password] {
  vertical-align: top;
}
input[type=radio], input[type=checkbox] {
  margin-right: 5px;
  cursor: pointer;
  vertical-align: middle;
}
input[type=number]::-webkit-inner-spin-button,
input [type=number]::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
}
input[type=number] {
  -moz-appearance: textfield;
}
input.hid {
  display: none;
}

a:focus,
input:focus,
select:focus,
button:focus {
  box-shadow: none !important;
  outline: none;
}

select {
  border: 0px;
  outline: none;
}

textarea {
  padding: 5px;
  border: 1px solid rgba(216, 216, 216, 0.8);
  box-sizing: border-box;
  resize: none;
  font-size: 16px;
  background-color: #eeeeee;
}

a {
  cursor: pointer;
  color: inherit;
  font-size: inherit;
  text-decoration: none;
}
a:hover, a:focus {
  text-decoration: none;
  color: currentColor;
}

img {
  max-width: 100%;
}

.sprite {
  fill: #b91a22;
  vertical-align: top;
  width: 100%;
  height: 100%;
  transition: 0.3s ease-in-out;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  margin: 0;
}

.font-button .far,
.font-button .fas {
  color: #666;
  transition: 0.08s cubic-bezier(0.37, 0.99, 0.92, 0.96);
}
.font-button:hover .far,
.font-button:hover .fas {
  color: #b91a22;
}

.zero-size {
  font-size: 0px;
  letter-spacing: 0px;
  word-spacing: 0px;
}

.required {
  color: #b91a22;
  margin: 0 0 0 8px;
}

.red {
  color: #ff0016;
}

.space {
  display: inline-block;
  width: 7px;
}

.clear {
  clear: both;
  height: 0px;
  line-height: 0px;
  font-size: 0px;
  overflow: hidden;
}

.ellipsis {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.hide {
  display: none !important;
}
@media (max-width: 1024px) {
  .hide {
    display: block !important;
  }
}

@keyframes menushow {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
@keyframes visitsLoad {
  0% {
    transform: translate3d(-100%, 0, 0);
  }
  30% {
    transform: translate3d(-70%, 0, 0);
  }
  80% {
    transform: translate3d(-20%, 0, 0);
  }
  95% {
    transform: translate3d(-5%, 0, 0);
  }
  100% {
    transform: translate3d(0%, 0, 0);
  }
}
@keyframes visitsShow {
  0% {
    opacity: 0;
    transform: translate3d(0, 45px, 0);
  }
  100% {
    opacity: 1;
    transform: translate3d(0, 0, 0);
  }
}
@keyframes moveCircle {
  0%, 100% {
    transform: translateY(-10%);
  }
  50% {
    transform: translateY(-5%);
  }
}
.table {
  border-top: 1px solid #c8dae8;
  border-right: 1px solid #c8dae8;
  border-left: 1px solid #c8dae8;
}

.input {
  width: 100%;
}

.textarea {
  width: 100%;
  height: 100px;
}

.searchBar {
  display: flex;
  align-items: center;
  white-space: nowrap;
}

.icon {
  display: inline-block;
  vertical-align: middle;
}

.mw-auto {
  max-width: none !important;
}

.wrapper-1800 {
  width: 100%;
  max-width: 1800px;
  margin: auto;
}

.wrapper-1750 {
  width: 100%;
  max-width: 1750px;
  margin: auto;
}

.wrapper-1700 {
  width: 100%;
  max-width: 1700px;
  margin: auto;
}

.wrapper-1650 {
  width: 100%;
  max-width: 1650px;
  margin: auto;
}

.wrapper {
  width: 100%;
  max-width: 1600px;
  margin: auto;
}

.wrapper-1550 {
  width: 100%;
  max-width: 1550px;
  margin: auto;
}

.wrapper-1500 {
  width: 100%;
  max-width: 1500px;
  margin: auto;
}

.wrapper-1450 {
  width: 100%;
  max-width: 1450px;
  margin: auto;
}

.wrapper-1400 {
  width: 100%;
  max-width: 1400px;
  margin: auto;
}

.wrapper-1350 {
  width: 100%;
  max-width: 1350px;
  margin: auto;
}

.wrapper-1300 {
  width: 100%;
  max-width: 1300px;
  margin: auto;
}

.wrapper-1250 {
  width: 100%;
  max-width: 1250px;
  margin: auto;
}

.wrapper-1200 {
  width: 100%;
  max-width: 1200px;
  margin: auto;
}

.wrapper-1100 {
  width: 100%;
  max-width: 1100px;
  margin: auto;
}

.wrapper-1050 {
  width: 100%;
  max-width: 1050px;
  margin: auto;
}

.wrapper-1000 {
  width: 100%;
  max-width: 1000px;
  margin: auto;
}

.wrapper-900 {
  width: 100%;
  max-width: 900px;
  margin: auto;
}

.wrapper-800 {
  width: 100%;
  max-width: 800px;
  margin: auto;
}

.detail_label {
  display: inline-block;
  vertical-align: middle;
  margin-top: -3px;
  padding: 0 6px;
  height: 20px;
  text-align: center;
  background-color: #fff;
  line-height: 20px;
  vertical-align: middle;
  font: 13px/25px Arial;
  border: 1px solid #fff;
}
.detail_label.fc-LightSalmon {
  border-color: #e9a3a8;
  color: #e9a3a8;
  line-height: 20px;
}

/* #Navigation
================================================== */
.start-header {
  opacity: 1;
  transform: translateY(0);
  transition: all 0.3s ease-out;
}
.start-header.scroll-on {
  position: fixed;
  box-shadow: 0 5px 10px 0 rgba(138, 155, 165, 0.15);
  padding: 10px 0;
  transition: all 0.3s ease-out;
}
@media (min-width: 1025px) {
  .start-header.scroll-on .navbar-brand img {
    transition: all 0.3s ease-out;
  }
  .start-header.scroll-on .search-formList {
    top: 105px;
  }
}
.start-header .container {
  max-width: 1870px;
  padding-right: 0;
  margin-right: 0;
}
.start-header .container img {
  margin: 0 !important;
}

.navigation-wrap {
  width: 100%;
  top: 0;
  left: 0;
  z-index: 1050;
  transition: all 0.3s ease-out;
  border-bottom: #495867 5px solid;
}

.navbar {
  align-items: stretch;
  padding: 0;
}
@media (min-width: 1050px) and (max-width: 1600px) {
  .navbar {
    flex-wrap: wrap;
    justify-content: center;
  }
}
@media (min-width: 1050px) and (max-width: 1500px) {
  .navbar .nav-link {
    font-size: 16px;
    font-size: 1rem;
  }
  .navbar .nav-item-search .nav-link {
    padding-top: 8px !important;
  }
}
@media (min-width: 1050px) and (max-width: 1300px) {
  .navbar .nav-item-search .nav-link {
    padding-top: 6px !important;
  }
}
@media (min-width: 1050px) and (max-width: 1150px) {
  .navbar {
    flex-direction: column;
  }
}
@media (min-width: 1000px) and (max-width: 1020px) {
  .navbar {
    align-items: flex-start;
  }
}
@media (max-width: 1080px) {
  .navbar {
    justify-content: space-between;
  }
}
.navbar-brand {
  padding: 0;
  margin: 0;
}
.navbar-brand img {
  display: block;
  transition: all 0.3s ease-out;
}
@media (min-width: 1025px) {
  .navbar-brand {
    margin-bottom: 0.15rem;
  }
}
@media (min-width: 1600px) {
  .navbar-brand {
    margin-right: 3em;
  }
}
@media (min-width: 1200px) and (max-width: 1300px) {
  .navbar-brand {
    max-width: 12.5em;
  }
}
@media (max-width: 1080px) {
  .navbar-brand {
    max-width: 13em;
    margin-left: -0.5rem;
  }
}
.navbar-light .navbar-toggler-icon {
  width: 28px;
  height: 17px;
  background-image: none;
  position: relative;
  border-bottom: 1px solid #000;
  transition: all 300ms linear;
}
.navbar-light .navbar-toggler-icon::after, .navbar-light .navbar-toggler-icon::before {
  width: 28px;
  position: absolute;
  height: 1px;
  background-color: #000;
  top: 0;
  left: 0;
  content: "";
  z-index: 2;
  transition: all 300ms linear;
}
.navbar-light .navbar-toggler-icon::after {
  top: 8px;
}
.navbar-light .navbar-nav .nav-link {
  display: inline-flex;
  white-space: normal;
}
.navbar-collapse {
  flex-wrap: wrap;
  align-items: stretch;
  justify-content: space-between;
}
@media (min-width: 1025px) {
  .navbar-collapse {
    flex-wrap: nowrap;
  }
}
@media (min-width: 800px) and (max-width: 1500px) {
  .navbar-collapse {
    width: 100%;
    margin-top: 0;
  }
}
@media (max-width: 1080px) {
  .navbar-collapse {
    position: fixed;
    width: 100%;
    height: 100vh;
    top: 0;
    left: -100%;
    background: #fff;
    transition: 0.15s;
    overflow-y: auto;
    max-width: 60vw;
  }
  .navbar-collapse, .navbar-collapse > ul {
    z-index: 100;
  }
  .navbar-collapse > ul {
    position: relative;
    justify-content: flex-start !important;
  }
  .navbar-collapse.show {
    display: block !important;
    left: 0;
  }
}
.navbar-overlay {
  display: block;
  width: 100%;
  height: 100vh;
  background-color: rgba(0, 0, 0, 0.75);
  position: fixed;
  top: 0;
  left: 0;
  z-index: 99;
  opacity: 0;
  visibility: hidden;
}
.navbar-overlay.active {
  opacity: 1;
  visibility: visible;
}
@media (min-width: 1025px) {
  .navbar-overlay {
    display: block;
  }
}
.navbar-toggler {
  float: right;
  border: none;
  padding-right: 0;
}
.navbar-toggler:active, .navbar-toggler:focus {
  outline: none;
}
@media (max-width: 1080px) {
  .navbar-toggler {
    display: block !important;
  }
}
.navbar-toggler .hamburger-toggle {
  position: relative;
  display: inline-block;
  width: 50px;
  height: 50px;
  z-index: 11;
}
.navbar-toggler .hamburger-toggle .hamburger {
  position: absolute;
  transform: translate(-50%, -50%) rotate(0deg);
  left: 50%;
  top: 50%;
  width: 50%;
  height: 50%;
  pointer-events: none;
}
.navbar-toggler .hamburger-toggle .hamburger span {
  width: 100%;
  height: 4px;
  position: absolute;
  background: #000;
  border-radius: 2px;
  z-index: 1;
  transition: transform 0.2s cubic-bezier(0.77, 0.2, 0.05, 1), background 0.2s cubic-bezier(0.77, 0.2, 0.05, 1), all 0.2s ease-in-out;
  left: 0px;
}
.navbar-toggler .hamburger-toggle .hamburger span:first-child {
  top: 10%;
  transform-origin: 50% 50%;
  transform: translate(0% -50%) !important;
}
.navbar-toggler .hamburger-toggle .hamburger span:nth-child(2) {
  top: 50%;
  transform: translate(0, -50%);
}
.navbar-toggler .hamburger-toggle .hamburger span:last-child {
  left: 0;
  top: auto;
  bottom: 0;
  transform-origin: 50% 50%;
}
.navbar-toggler .hamburger-toggle .hamburger.active span {
  position: absolute;
  margin: 0;
}
.navbar-toggler .hamburger-toggle .hamburger.active span:first-child {
  top: 45%;
  transform: rotate(45deg);
}
.navbar-toggler .hamburger-toggle .hamburger.active span:nth-child(2) {
  left: 50%;
  width: 0px;
}
.navbar-toggler .hamburger-toggle .hamburger.active span:last-child {
  top: 45%;
  transform: rotate(-45deg);
}
.navbar-toggler.fixed {
  display: none;
  position: fixed;
  left: 59%;
  top: 2%;
  z-index: 1001;
}
.navbar-toggler.fixed .hamburger-toggle .hamburger span {
  background-color: #fff;
}
.navbar-toggler.fixed .hamburger-toggle .hamburger span:first-child {
  transform: rotate(40deg);
}
.navbar-toggler.fixed .hamburger-toggle .hamburger span:last-child {
  transform: translateY(-18px) rotate(-40deg);
}
.navbar-toggler.fixed.active {
  display: block;
}
.navbar-nav.link {
  flex: 1;
  justify-content: space-between;
}
@media (min-width: 1025px) {
  .navbar-nav.link {
    position: relative;
    flex: 1;
    padding-left: 1em;
    padding-right: 1em;
    margin: auto;
    justify-content: space-around;
  }
  .navbar-nav.link .nav-link {
    flex-direction: column;
    --min-size: 18;
    --max-size: 20;
    --font-size: calc((var(--min-size) * 1px) + (var(--max-size) - var(--min-size)) * ((100cqw - 320px) / (1920 - 320)));
    font-size: clamp(var(--min-size) * 1px, var(--font-size), var(--max-size) * 1px);
  }
}
@media (min-width: 1025px) and (min-width: 1200px) and (max-width: 1400px) {
  .navbar-nav.link .nav-link {
    --min-size: 16;
    --max-size: 18;
  }
}
@media (min-width: 1025px) {
  .navbar-nav.link .nav-item {
    position: relative;
    flex: 1;
    text-align: center;
    padding-left: 1em !important;
    padding-right: 1em !important;
  }
  .navbar-nav.link .nav-item:not(:last-child)::after {
    content: url("../images/nav-item-dot.jpg");
    position: absolute;
    right: 0;
    top: 50%;
    transform: translateY(-50%);
  }
  .navbar-nav.link .nav-item > .dropdown-menu {
    left: 50%;
    transform: translate3d(-50%, 0px, 0);
    background-color: transparent;
    box-shadow: none;
    padding-top: 30px !important;
  }
  .navbar-nav.link .nav-item > .dropdown-menu .dropdown-menu-list {
    background-color: #b91a22;
    box-shadow: 0 5px 10px 0 rgba(138, 155, 165, 0.15);
    padding: 6px;
  }
  .navbar-nav.link .nav-item > .dropdown-menu .dropdown-item {
    color: #fff;
    padding-bottom: 6px;
  }
  .navbar-nav.link .nav-item > .dropdown-menu .dropdown-item:hover {
    color: #1b7cb9;
  }
  .navbar-nav.link .nav-item:hover::after, .navbar-nav.link .nav-item.active::after {
    display: block;
  }
  .navbar-nav.link .nav-item:hover > .dropdown-menu {
    display: block;
    opacity: 1;
    visibility: visible;
    max-height: inherit;
  }
}
@media (max-width: 1080px) {
  .navbar-nav.link {
    flex-direction: column;
  }
  .navbar-nav.link .nav-item {
    padding-left: 1.5rem !important;
    padding-right: 0 !important;
  }
}
.navbar-nav.info .nav-item {
  display: flex;
  background-color: #f6f6f6;
}
.navbar-nav.info .nav-item:nth-child(even) {
  background-color: #e8e8e8;
}
.navbar-nav.info .nav-item .nav-link {
  --min-size: 15;
  --max-size: 16;
  --font-size: calc((var(--min-size) * 1px) + (var(--max-size) - var(--min-size)) * ((100cqw - 320px) / (1920 - 320)));
  font-size: clamp(var(--min-size) * 1px, var(--font-size), var(--max-size) * 1px);
  align-items: center;
  margin: 0.75rem 1.5rem;
}
@media (min-width: 1101px) and (max-width: 1400px) {
  .navbar-nav.info .nav-item .nav-link {
    margin: 1rem 0.5rem;
  }
}
@media (max-width: 1080px) {
  .navbar-nav.info {
    flex-wrap: wrap;
  }
  .navbar-nav.info > li {
    flex: 1;
  }
  .navbar-nav.info > li.nav-item-search, .navbar-nav.info > li.nav-item-social {
    display: none;
  }
  .navbar-nav.info > li.nav-item-lang {
    flex: 0 0 100%;
    max-width: 100%;
    margin-top: 0.5rem;
    order: 1;
  }
}
.navbar-mobile-icon {
  width: 80px;
  height: 40px;
  color: #000 !important;
  text-align: right;
  padding-right: 18px;
  position: absolute;
  right: 0;
  top: 3px;
  line-height: 40px;
}
.navbar-mobile-icon::after {
  display: none;
}
.navbar-mobile-icon.show .bi {
  transform: rotate(180deg);
}

.nav-link {
  position: relative;
  display: inline-block;
  font-size: 0.95em;
  color: #696969 !important;
  font-weight: 500;
  padding: 5px 0 !important;
  transition: all 200ms linear;
  font-size: 20px;
  font-size: 1.25rem;
}
@media (min-width: 900px) and (max-width: 1100px) {
  .nav-link {
    font-size: 17px;
    font-size: 1.0625rem;
  }
}
@media (max-width: 1080px) {
  .nav-link {
    padding: 10px 0 !important;
  }
  .nav-link br {
    display: none;
  }
}
.nav-item {
  position: relative;
  transition: all 200ms linear;
}
.nav-item.active .nav-link, .nav-item:hover .nav-link {
  color: #000 !important;
}
.nav-item .icon {
  flex: none;
}
.nav-item .icon svg {
  vertical-align: middle;
  margin-top: -3px;
  margin-right: 10px !important;
}
.nav-item .icon .bi-search {
  font-size: 1.5em;
  margin-top: 2.5px;
}
.nav-item .icon .bi-caret-down-fill {
  margin-left: 5px;
  font-size: 14px;
}
.nav-item img {
  margin: 0 !important;
}
@media (max-width: 1080px) {
  .nav-item::after {
    display: none;
  }
  .nav-item::before {
    position: absolute;
    display: block;
    top: 24px;
    left: 5px;
    width: 11px;
    height: 1px;
    content: "";
    border: none;
    background-color: #000;
  }
}

@media (max-width: 1080px) {
  .nav-item.nav-item-lang .nav-link .icon:last-child {
    display: none !important;
  }
  .nav-item.nav-item-lang .dropdown-menu {
    position: relative;
    opacity: 1;
    width: 100%;
    max-height: 100%;
    display: flex;
    visibility: visible;
    padding: 0 !important;
    transform: translate3d(0, 5px, 0);
  }
  .nav-item.nav-item-lang .dropdown-menu .dropdown-item {
    font-size: 16px;
    font-size: 1rem;
    text-align: center;
    line-height: 2;
    background-color: #b91a22;
    color: #fff;
    border-radius: 0;
  }
  .nav-item.nav-item-lang .dropdown-menu .dropdown-item + .dropdown-item {
    margin-left: 4px;
  }
}
/* #Primary style
================================================== */
.bg-light {
  background-color: #fff !important;
  transition: all 200ms linear;
}

.dropdown-menu {
  padding: 10px !important;
  margin: 0;
  font-size: 13px;
  letter-spacing: 1px;
  color: #212121;
  background-color: #fcfaff;
  border: none;
  border-radius: 3px;
  box-shadow: 0 5px 10px 0 rgba(138, 155, 165, 0.15);
  transition: all 200ms linear;
}
.dropdown-toggle::after {
  display: none;
}
.dropdown-item {
  font-size: 17px;
  font-size: 1.0625rem;
  padding: 7.5px 15px 3px;
  color: #212121;
  border-radius: 2px;
  transition: all 200ms linear;
}
.dropdown-item:hover, .dropdown-item:focus {
  color: #fff;
  background-color: #b91a22;
}

.nav-item .dropdown-menu {
  transform: translate3d(0, 10px, 0);
  visibility: hidden;
  opacity: 0;
  max-height: 0;
  display: none;
  padding: 0;
  margin: 0;
  transition: all 200ms linear;
}
.nav-item .dropdown-menu.show {
  display: block;
  opacity: 1;
  visibility: visible;
  max-height: 999px;
  transform: translate3d(-10px, 0px, 0);
}
@media (max-width: 1080px) {
  .nav-item-lang .dropdown-toggle {
    display: none !important;
  }
}

@media (max-width: 1080px) {
  .nabar-icon-mobile {
    display: flex !important;
    align-items: center;
    justify-content: flex-end;
    padding-right: 1em;
  }
  .nabar-icon-mobile .icon {
    margin-left: 5px;
  }
  .nabar-icon-mobile .icon-search {
    width: 25px;
    margin-right: 10px;
  }
}

/* #Media
================================================== */
@media (max-width: 810px) {
  .dropdown-toggle[aria-expanded=true]::after {
    transform: rotate(90deg);
    opacity: 0;
  }
}
.search-formList {
  position: absolute;
  top: 129px;
  width: 100%;
  height: 100vh;
  background: rgba(0, 0, 0, 0.75);
  z-index: 1;
  transition: 0.5s;
  display: none;
}
.search-formList.open {
  display: block;
}
@media (min-width: 1301px) and (max-width: 1400px) {
  .search-formList {
    top: 6.25em;
  }
}
@media (min-width: 1025px) and (max-width: 1300px) {
  .search-formList {
    top: 5.25em;
  }
}
@media (min-width: 800px) and (max-width: 1024px) {
  .search-formList {
    top: 8.75em;
  }
}
@media (max-width: 810px) {
  .search-formList {
    top: 6em;
  }
}
.search-formInput {
  position: absolute;
  top: 0px;
  right: 0%;
  width: 100%;
  max-height: 60px;
  overflow: hidden;
  background-color: #e1e4e7;
  max-width: 40em;
}
.search-formInput fieldset {
  position: relative;
  margin: 0 60px 0 0;
  padding: 0;
  border: none;
}
.search-formInput input {
  display: block;
  height: 60px;
}
.search-formInput input[type=search] {
  width: 100%;
  padding: 0 5px 0 22px;
  background: transparent;
  border: none;
}
.search-formInput input[type=submit] {
  position: absolute;
  bottom: 0;
  right: 0;
  width: 80px;
  margin: 0;
  padding: 0;
  font-weight: 700;
  text-transform: uppercase;
  color: white;
  background: #b91a22;
  border: none;
  cursor: pointer;
}

footer {
  background-repeat: no-repeat;
  background-position: top;
  background-size: cover;
  background-image: url("../images/footer-bg.png");
  padding-top: 3.90625vw;
}
footer .footer-top {
  display: flex;
  padding-top: 45px;
  padding-bottom: 40px;
}
@media (min-width: 1101px) and (max-width: 1600px) {
  footer .footer-top {
    padding: 1em 2em;
  }
}
@media (max-width: 1024px) {
  footer .footer-top {
    padding: 1em;
  }
}
@media (max-width: 1024px) {
  footer .footer-head {
    text-align: center;
    margin-top: 10px;
    margin-bottom: 20px;
  }
}
footer .footer-info {
  font-size: 15px;
  font-size: 0.9375rem;
  font-weight: 100;
}
footer .footer-info p {
  margin-bottom: 0;
}
footer .footer-info .icon {
  margin-top: -5px;
  margin-right: 4px;
}
footer .footer-info-logo img {
  display: block;
  max-width: 100%;
}
@media (max-width: 1024px) {
  footer .footer-info-logo {
    margin-bottom: 1.5rem;
  }
}
footer .footer-info-wrap {
  justify-content: space-between;
  flex-direction: column;
  width: 100%;
}
footer .footer-info-center {
  width: 100%;
}
footer .footer-info-center .footer-info .item {
  position: relative;
  margin-top: 0.5rem;
  padding-left: 3.75rem;
}
footer .footer-info-center .footer-info .item .icon {
  position: absolute;
  left: 0;
}
footer .footer-info-center .footer-info .item-txt {
  --min-size: 18;
  --max-size: 20;
  --font-size: calc((var(--min-size) * 1px) + (var(--max-size) - var(--min-size)) * ((100cqw - 320px) / (1920 - 320)));
  font-size: clamp(var(--min-size) * 1px, var(--font-size), var(--max-size) * 1px);
  line-height: 1.25;
}
footer .footer-info-center .footer-info .item-txt span {
  color: #818282;
  font-family: "Roboto", "Noto Sans TC";
  --min-size: 16;
  --max-size: 18;
  --font-size: calc((var(--min-size) * 1px) + (var(--max-size) - var(--min-size)) * ((100cqw - 320px) / (1920 - 320)));
  font-size: clamp(var(--min-size) * 1px, var(--font-size), var(--max-size) * 1px);
  padding-top: 5px;
}
@media (max-width: 640px) {
  footer .footer-info-center .footer-info .item {
    text-align: center;
    margin-top: 1.5em;
  }
  footer .footer-info-center .footer-info .item:not(:last-child) {
    margin-bottom: 3em;
  }
  footer .footer-info-center .footer-info .item .icon {
    top: 50%;
    transform: translateY(-50%);
  }
  footer .footer-info-center .footer-info .item-txt {
    display: flex;
    align-items: center;
  }
  footer .footer-info-center .footer-info .item-txt span {
    flex: 1;
    text-align: left;
    padding-left: 10px;
    padding-top: 0;
  }
}
@media (min-width: 1025px) {
  footer .footer-info-center {
    flex: 1;
    padding: 1vw 2vw 0;
  }
  footer .footer-info-center .footer-info {
    display: flex;
    flex-flow: row wrap;
    justify-content: space-between;
  }
  footer .footer-info-center .footer-info .item ~ .item {
    margin-left: 1.25rem;
  }
}
footer .footer-info-bottom {
  padding-top: 2.34375vw;
  border-top: #c6d0d5 1px solid;
}
@media (min-width: 1025px) {
  footer .footer-info-bottom {
    padding-top: 1.0526315789vw;
  }
  footer .footer-info-bottom .footer-info {
    display: flex;
    align-items: center;
  }
  footer .footer-info-bottom .footer-info p ~ p {
    margin-left: 10px;
  }
}
@media (max-width: 1024px) {
  footer .footer-info-bottom .iware {
    display: inline-block;
    margin-top: 5px;
  }
}
@media (min-width: 1025px) {
  footer {
    padding-top: 11.8421052632vw;
  }
}
@media (max-width: 1024px) {
  footer {
    margin-bottom: 2em;
  }
}

.divider {
  width: 2px;
  height: 100%;
  background: #bfb1a5;
}
@media (min-width: 800px) and (max-width: 1000px) {
  .divider {
    display: none;
  }
}

.copyright {
  --min-size: 13;
  --max-size: 14;
  --font-size: calc((var(--min-size) * 1px) + (var(--max-size) - var(--min-size)) * ((100cqw - 320px) / (1920 - 320)));
  font-size: clamp(var(--min-size) * 1px, var(--font-size), var(--max-size) * 1px);
  font-family: "Roboto";
}

.iware {
  font-size: 87%;
  font-weight: 100;
  font-weight: lighter;
  margin-bottom: 0 !important;
  padding: 4px 8px;
}
.iware,
.iware a {
  color: #aaaaaa;
}
.iware a:hover {
  text-decoration: underline;
}
.iware b {
  font-weight: 100;
}

.float-link {
  right: -100%;
  position: fixed;
  bottom: 200px;
  z-index: 10;
  transition: 0.3s;
}
.float-link .link-btn a:hover {
  opacity: 0.65;
}
@media (min-width: 1025px) {
  .float-link .link-btn.nabar-icon-mobile {
    display: none;
  }
}
.float-link .link-btn.nabar-icon-mobile img {
  max-width: 100%;
}
@media (max-width: 1024px) {
  .float-link .link-btn.top {
    display: none;
  }
}
.float-link.active {
  right: 15px;
}
@media (max-width: 1600px) {
  .float-link.active {
    right: 10px;
  }
}
@media (max-width: 1024px) {
  .float-link {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    bottom: 0;
    right: 0 !important;
    background: #b91a22;
    padding: 5px;
    margin: 0;
  }
  .float-link .link-btn {
    text-align: center;
    margin: 0;
  }
  .float-link .link-btn,
.float-link .link-btn .link-btn-toggle {
    display: block;
    width: 100%;
  }
  .float-link .link-btn .link-btn-toggle {
    color: #fff;
  }
  .float-link .link-btn .icon small {
    font-size: 100%;
  }
}

.cookies {
  display: none;
  width: 100%;
  background: #303030;
  color: #fff;
  font-size: 16px;
  font-size: 1rem;
  padding: 1.75rem 1rem 1.75rem;
  position: fixed;
  bottom: 0;
  left: 0;
  z-index: 60;
}
.cookies p {
  font-weight: 400;
  text-align: center;
  margin-bottom: 0.5rem;
}
.cookies p:last-child {
  margin-bottom: 0;
}
@media (min-width: 1025px) {
  .cookies p {
    text-align: left;
  }
}
.cookies .btn {
  text-decoration: none !important;
  border-radius: 10px !important;
}
@media (min-width: 800px) {
  .cookies .btn {
    padding-top: 0.125rem;
    padding-bottom: 0.125rem;
  }
}
@media (max-width: 640px) {
  .cookies .btn {
    display: block;
    width: 100%;
    font-size: 16px;
    font-size: 1rem;
  }
}
.cookies-wrapper {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
}
@media (max-width: 1024px) {
  .cookies-wrapper {
    flex-direction: column;
  }
}
.cookies.display {
  display: block;
  animation: cookies 1s 1;
}
.cookies.close-cookies {
  transition: all 0.2s ease-out;
  transform: translate3d(0, 100%, 0);
}
@media (max-width: 810px) {
  .cookies.close-cookies {
    bottom: -2%;
  }
}
@media (min-width: 1025px) {
  .cookies .btn {
    font-size: 18px;
    font-size: 1.125rem;
    margin-left: 2.5em;
    margin-bottom: 0.25em;
    padding: 0.5rem 2.5rem;
  }
}
@media (max-width: 810px) {
  .cookies {
    width: calc(100% - 1em);
    border-top: none;
    margin: 0.5em;
    padding-top: 1rem;
    padding-bottom: 1rem;
    font-size: 14px;
    font-size: 0.875rem;
  }
  .cookies.display {
    flex-direction: column;
  }
}

@keyframes cookies {
  0% {
    transform: translate3d(0, 100%, 0);
  }
  100% {
    transform: translate3d(0, 0%, 0);
  }
}
.article,
article {
  width: 100%;
  font-size: 100%;
}
.article .unreset_iframe,
article .unreset_iframe {
  width: 100%;
  height: 750px;
}
@media (max-width: 810px) {
  .article .unreset_iframe,
article .unreset_iframe {
    height: 350px;
  }
}

.unreset {
  line-height: initial;
}
.unreset a {
  text-decoration: underline;
}
.unreset img {
  max-width: 100% !important;
  height: auto !important;
}
.unreset p {
  font-size: 1rem;
  line-height: 1.5;
}

.in-content {
  padding-top: 0.25em;
}
@media (max-width: 1700px) {
  .in-content {
    padding-left: 2em;
    padding-right: 2em;
  }
}
@media (max-width: 810px) {
  .in-content {
    padding-left: 1em;
    padding-right: 1em;
  }
}

.imgCenter-4-3 {
  position: relative;
  width: 100%;
  height: 0;
  padding-bottom: 59.5%;
  overflow: hidden;
}
.imgCenter-4-3 img {
  max-height: none;
}

.boxShadow, .contact-page-wrapper .contact-form {
  box-shadow: 0 0 10px 5px rgba(0, 0, 0, 0.1) !important;
}

.bt-solid-c1 {
  border-top: 1px #ccc solid;
}

.mainContent {
  overflow: hidden;
}
@media (min-width: 1025px) {
  .mainContent-info {
    width: 300px;
  }
  .mainContent-info ~ .mainContent-list {
    max-width: calc(100% - 300px);
    padding-left: 45px;
    padding-right: 5px;
  }
}
@media (min-width: 1000px) and (max-width: 1500px) {
  .mainContent-info {
    padding-left: 1em;
    padding-right: 1em;
  }
  .mainContent-info ~ .mainContent-list {
    padding-left: 1em;
    padding-right: 1em;
  }
}
@media (max-width: 1024px) {
  .mainContent-info .link-wrap {
    display: none;
  }
  .mainContent-info .link-wrap.cur {
    display: block;
  }
}
.mainContent-list {
  max-width: 100%;
  flex: 1;
}
.mainContent-list .card-title {
  --max-size: 20;
  font-weight: 500 !important;
}
.mainContent-list .card-img-top {
  text-align: center;
  background: #e7e7e7;
  height: 0;
  padding-top: 55%;
}
.mainContent-list .card-img-top img {
  display: inline-block;
  width: auto;
}
@media (min-width: 1025px) {
  .mainContent-list .card-body {
    padding-bottom: 1.75em;
  }
}
@media (max-width: 1024px) {
  .mainContent-list {
    padding-left: 1em;
    padding-right: 1em;
  }
}
@media (max-width: 1024px) {
  .mainContent {
    flex-direction: column;
  }
}

.main-pageCircle-wrap.top-left .pageCircle {
  left: 0;
}
.main-pageCircle-wrap.bottom-right .pageCircle {
  top: auto;
  bottom: 0;
  left: auto;
  right: 0;
}

.link-wrap ul {
  padding-left: 1.5789473684vw;
  margin-right: 1.5789473684vw;
  border-right: #d3d3d3 1px solid;
}
.link-wrap ul > li {
  position: relative;
  border-bottom: #d3d3d3 2px solid;
}
.link-wrap ul > li > a {
  position: relative;
  display: block;
  line-height: 1.5;
  color: #222222;
  padding: 1rem 0.95rem 0.75rem;
  padding-left: 1.7em;
  background-color: transparent !important;
  transition: 0.25s;
  font-weight: 400;
}
.link-wrap ul > li::before {
  content: "";
  display: block;
  width: 0;
  height: 0;
  border-left: 8px solid #d3d3d3;
  border-right: 8px solid transparent;
  border-bottom: 8px solid transparent;
  border-top: 8px solid transparent;
  position: absolute;
  right: -1.05rem;
  top: 50%;
  margin-top: -4px;
  opacity: 0;
}
.link-wrap ul > li::after {
  content: "";
  display: block;
  width: 0;
  height: 0;
  border-left: 8px solid #fff;
  border-right: 8px solid transparent;
  border-bottom: 8px solid transparent;
  border-top: 8px solid transparent;
  position: absolute;
  right: -1rem;
  top: 50%;
  margin-top: -4px;
  opacity: 0;
}
.link-wrap ul > li:hover, .link-wrap ul > li.active {
  border-bottom-color: #b91a22;
}
.link-wrap ul > li:hover::before, .link-wrap ul > li:hover::after, .link-wrap ul > li.active::before, .link-wrap ul > li.active::after {
  opacity: 1;
}
.link-wrap ul > li:hover a, .link-wrap ul > li.active a {
  color: #b91a22;
}
@media (max-width: 1024px) {
  .link-wrap {
    margin-left: 1.5em;
    margin-right: 1.5em;
    margin-top: 1em;
    margin-bottom: 2rem;
  }
  .link-wrap ul {
    padding-left: 4.6875vw;
    margin-right: 4.6875vw;
  }
  .link-wrap ul > li::before {
    right: -1.1rem;
  }
}
.link-head {
  --min-size: 20;
  --max-size: 30;
  --font-size: calc((var(--min-size) * 1px) + (var(--max-size) - var(--min-size)) * ((100cqw - 320px) / (1920 - 320)));
  font-size: clamp(var(--min-size) * 1px, var(--font-size), var(--max-size) * 1px);
  color: #fff;
  border-radius: 40px;
  background-color: #b91a22;
  text-align: center;
  line-height: 1;
  padding-top: 0.75em;
  padding-bottom: 0.45em;
}

.unit {
  position: relative;
  display: block;
  line-height: 1.5;
  text-align: center;
  padding-left: 10px;
  padding-right: 10px;
}
.unit .btn {
  padding-top: 0.6em;
  padding-bottom: 0.6em;
}
.unit-icon {
  padding-left: 1.5em !important;
  padding-right: 0;
}
.unit-icon .icon {
  margin-top: 0;
  position: absolute;
  left: 0;
  top: -0.1rem;
}
@media (max-width: 1024px) {
  .unit {
    text-align: left;
    font-size: 16px;
  }
  .unit-icon .icon {
    top: 0.85rem;
  }
}

.select_style::after {
  content: "\f282";
  font-family: "bootstrap-icons" !important;
  display: block;
  font-size: 14px;
  font-size: 0.875rem;
  position: absolute;
  right: 8px;
  top: 1px;
}
.select_style select.form-control {
  background-color: #fff;
  border: 1px solid #e6eaea !important;
  text-align: left;
  border-radius: 0;
  height: 40px !important;
  min-height: auto;
  padding-top: 0;
  padding-bottom: 0;
}
.select_style select {
  width: 100%;
  background: transparent;
  appearance: none;
  -webkit-appearance: none;
}
.select_style select,
.select_style input {
  border: none !important;
  height: 100% !important;
}
.select_style .form-control {
  border-radius: 0;
}
.select_style .disabled {
  background-color: #e9ecef;
}
.select_style .disabled select,
.select_style .disabled input {
  background: transparent;
}

.title-wrap {
  text-align: center;
  padding: 10px;
  margin-top: 10px;
  margin-bottom: 10px;
}
.title-wrap .btn .icon {
  font-size: 23px;
  font-size: 1.4375rem;
}
@media (max-width: 1024px) {
  .title-wrap {
    margin-bottom: 2px;
  }
}
.title-en {
  font-family: "Mitr", sans-serif;
  font-size: 35px;
  font-size: 2.1875rem;
  text-transform: uppercase;
  margin-bottom: 0;
}
@media (max-width: 810px) {
  .title-en {
    font-size: 6.25vw;
  }
}
.title-ch {
  position: relative;
  font-size: 45px;
  font-size: 2.8125rem;
  color: #595757;
}
@media (max-width: 810px) {
  .title-ch {
    font-size: 4.375vw;
  }
}
@media (max-width: 640px) {
  .title-ch {
    font-size: 7.5vw;
  }
}
.title-mainWrap {
  justify-content: space-between;
  padding-top: 0.25em;
}
@media (max-width: 1024px) {
  .title-mainWrap {
    padding-top: 0.65em;
    padding-bottom: 0.65em;
  }
}
.titleStyle {
  position: relative;
  padding: 0.25em;
}
.titleStyle::before {
  content: "";
  display: block;
  width: 8px;
  height: 8px;
  background-color: #1b7cb9;
  border-radius: 50%;
  position: absolute;
  right: -8px;
  top: 8px;
}
.title__topLine {
  border-top-width: 2px;
  border-top-style: solid;
  border-top-color: #000;
}
.title__solid {
  border-bottom-width: 1px;
  border-bottom-style: solid;
  border-bottom-color: #b3b3b3;
}
.title__underLine {
  border-bottom-width: 2px;
  border-bottom-style: solid;
  border-bottom-color: #000;
}
.title-left-circle {
  position: relative;
  padding-left: 30px;
  z-index: 1;
}
.title-left-circle::before {
  content: "";
  position: absolute;
  width: 10px;
  height: 10px;
  border: #b91a22 2px solid;
  border-radius: 50%;
  top: 11px;
  left: 10px;
  z-index: -1;
}
.title-right-squre {
  position: relative;
  padding-left: 15px;
  z-index: 1;
}
.title-right-squre::before {
  content: "";
  width: 15px;
  height: 15px;
  background-color: #0125fd;
  position: absolute;
  bottom: 2px;
  right: -40px;
  z-index: -1;
}
.title__topHead::before {
  content: "";
  display: block;
  width: 30px;
  height: 0.5rem;
  background-color: #b91a22;
  margin-bottom: 1rem;
}

.heading-title {
  --min-size: 25;
  --max-size: 38;
  --font-size: calc((var(--min-size) * 1px) + (var(--max-size) - var(--min-size)) * ((100cqw - 320px) / (1920 - 320)));
  font-size: clamp(var(--min-size) * 1px, var(--font-size), var(--max-size) * 1px);
  color: #565656;
  font-family: "Josefin Sans", sans-serif;
}
.heading-title-item {
  --min-size: 16;
  --max-size: 20;
  --font-size: calc((var(--min-size) * 1px) + (var(--max-size) - var(--min-size)) * ((100cqw - 320px) / (1920 - 320)));
  font-size: clamp(var(--min-size) * 1px, var(--font-size), var(--max-size) * 1px);
  display: inline-block;
  border: 2px solid #d6d6d6;
  padding: 0.45em 1em 0.35em;
  margin-top: 0.35em;
  font-family: "Noto Sans TC", "Microsoft JhengHei", "微軟正黑體", "Arial", sans-serif;
}
.heading-title-item:before, .heading-title-item:after {
  content: "";
  display: block;
  width: 80%;
  height: 10px;
  background-color: #fff;
  position: absolute;
  left: 13px;
}
.heading-title-item:before {
  top: -5px;
}
.heading-title-item:after {
  bottom: -5px;
}
.heading-title-wrap {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

/* 進階版自適應文字大小 : 主標題
<div class="clamp-container">
    <h4 class="clamp-title-20to35">會員資料</h4>
</div> */
.clamp-container {
  container-type: inline-size;
}
.clamp-container-main::before {
  content: "";
  display: block;
  width: 100%;
  height: 1px;
  background-color: #bfbfbf;
  position: absolute;
  bottom: 0;
}
@media (max-width: 1024px) {
  .clamp-container {
    margin-top: 1.5em;
    margin-bottom: 1.5em;
  }
}
.clamp-ImgStyle::after {
  content: "";
  display: block;
  width: 100%;
  height: 244px;
  max-width: 13.3157894737vw;
  background-repeat: no-repeat;
  background-position: top;
  background-size: 13.3157894737vw;
  background-image: url("../images/img-outline-rhombic.png");
  position: absolute;
  right: 0.5263157895vw;
  top: 50%;
  margin-top: -60px;
  z-index: -1;
}
@media (max-width: 1024px) {
  .clamp-ImgStyle::after {
    opacity: 0.5;
    width: 100%;
    height: 130px;
    max-width: 100%;
    background-size: cover;
  }
}
.clamp-title-20to30 {
  --min-size: 20;
  --max-size: 30;
  --font-size: calc((var(--min-size) * 1px) + (var(--max-size) - var(--min-size)) * ((100cqw - 320px) / (1920 - 320)));
  font-size: clamp(var(--min-size) * 1px, var(--font-size), var(--max-size) * 1px);
}
.clamp-title-submain {
  --min-size: 18;
  --max-size: 24;
  --font-size: calc((var(--min-size) * 1px) + (var(--max-size) - var(--min-size)) * ((100cqw - 320px) / (1920 - 320)));
  font-size: clamp(var(--min-size) * 1px, var(--font-size), var(--max-size) * 1px);
}
.clamp-title-main {
  --min-size: 20;
  --max-size: 130;
  --font-size: calc((var(--min-size) * 1px) + (var(--max-size) - var(--min-size)) * ((100cqw - 320px) / (1920 - 320)));
  font-size: clamp(var(--min-size) * 1px, var(--font-size), var(--max-size) * 1px);
  margin-top: 12.5px;
  padding-bottom: 20px;
}
.clamp-title-main.en {
  font-family: "good-times", sans-serif;
  color: #d9dadb;
}

.breadcrum-wrap {
  margin-top: 0.7894736842vw;
}
@media (max-width: 1024px) {
  .breadcrum-wrap {
    margin-top: 2.34375vw;
    margin-bottom: 2.34375vw;
  }
}
.breadcrum-wrap .breadcrumb {
  font-size: 16px;
  font-size: 1rem;
  color: #404040;
  background-color: transparent;
  margin-bottom: 0;
}
.breadcrum-wrap .breadcrumb .breadcrumb-item {
  color: #777;
}
.breadcrum-wrap .breadcrumb .breadcrumb-item + .breadcrumb-item::before {
  content: "\f285";
  font-family: "bootstrap-icons" !important;
  padding-right: 0.65em;
}
.breadcrum-wrap .breadcrumb .breadcrumb-item.active {
  color: #000;
}
@media (min-width: 1025px) {
  .breadcrum-wrap .breadcrumb {
    justify-content: flex-end;
    padding-top: 0.75rem;
  }
}
@media (max-width: 1024px) {
  .breadcrum-wrap .breadcrumb {
    padding-left: 0;
    padding-top: 0.3em;
    padding-bottom: 0.75rem;
  }
}
.breadcrum-wrap .icon {
  margin-top: 0px;
  margin-right: 2px;
}
.breadcrum-wrap .icon img {
  vertical-align: initial;
}

.pagination-wrap {
  justify-content: center;
  margin-top: 2rem;
  margin-bottom: 1rem;
}
.pagination-wrap .pagination {
  justify-content: center;
}
.pagination-wrap .pagination .page-link {
  width: 33px;
  height: 33px;
  line-height: 33px;
  color: #959696;
  background-color: transparent;
  border-color: transparent;
  border-radius: 50%;
  text-align: center;
  padding: 0;
  margin-right: 0.5rem;
}
.pagination-wrap .pagination .page-link:hover, .pagination-wrap .pagination .page-link.active {
  color: #fff;
  background-color: #b91a22;
  border-color: #b91a22;
}
.pagination-wrap .pagination-select {
  display: flex;
  align-items: center;
}
.pagination-wrap .pagination-select select {
  text-align: center;
  appearance: auto;
  font-size: 15px;
  font-size: 0.9375rem;
}
.pagination-wrap .pagination-select .pagination-item {
  padding-left: 15px;
}
.pagination-wrap .pagination-mobile {
  max-width: 120px;
}
@media (min-width: 1025px) {
  .pagination-wrap .pagination-mobile .pagination-select {
    width: auto;
    padding-left: 5px;
  }
  .pagination-wrap .pagination-mobile .pagination-select select {
    max-width: 45px;
  }
}
@media (max-width: 1024px) {
  .pagination-wrap {
    margin-top: 0.5rem;
    margin-bottom: 2rem;
  }
}

.text-item {
  color: #1b7cb9 !important;
}
.text-abbr {
  color: #fd5604 !important;
}
.text-underLine {
  text-decoration: underline !important;
}
.text-uppercase {
  text-transform: uppercase;
}
.text-italic {
  font-style: italic;
}

.fw-100 {
  font-weight: 100 !important;
}
.fw-200 {
  font-weight: 200 !important;
}
.fw-300 {
  font-weight: 300 !important;
}
.fw-400 {
  font-weight: 400 !important;
}
.fw-600 {
  font-weight: 600 !important;
}
.fw-700 {
  font-weight: 700 !important;
}
.fw-900, .index-pageInterview .swiper-el-wrapper .swiper-button-prev,
.index-pageInterview .swiper-el-wrapper .swiper-button-next, .card-title, .card-time-date, .link-head, .title-en, .title-ch, .heading-title {
  font-weight: 900 !important;
}

.bg-itemSub {
  background-color: #6f6e6e !important;
}

.justify {
  text-align: justify;
}

.flex-none {
  flex: none !important;
}
@media (max-width: 640px) {
  .flex-none {
    width: 100%;
  }
}
.flex-auto {
  flex: auto !important;
}
.flex-1 {
  flex: 1 !important;
}

.page-wrapper {
  margin-top: 50px;
  padding-bottom: 70px;
}
@media (max-width: 1024px) {
  .page-wrapper {
    margin-top: 1em;
    margin-bottom: 1em;
    padding-bottom: 1em;
  }
}
.pageGrid {
  display: grid;
  grid-column-gap: 0;
  grid-auto-rows: 0fr 0fr 0fr;
}
.pageGrid-list-2to1 {
  grid-template-columns: 2fr 1fr 1fr;
}
.pageGrid-list-avg {
  grid-template-columns: 1fr 1fr 1fr 1fr;
}
.pageGrid-list-1to2 {
  grid-template-columns: 1fr 1fr 2fr;
}
.pageGrid-list-averageTo50p {
  grid-template-columns: repeat(2, 50%);
}
.pageGrid-list-averageToAuto-2 {
  grid-template-columns: repeat(2, auto);
}
@media (max-width: 1080px) {
  .pageGrid {
    grid-template-columns: auto !important;
  }
}
@media (max-width: 1024px) {
  .pageGrid {
    display: block;
  }
}

.tag-item, .tag-item-outline-main {
  display: inline-block;
  width: auto;
  line-height: 1.1;
  margin: 2.5px;
  font-size: 14px;
  line-height: 1.5;
  padding: 6px;
  text-align: center;
  background-color: #eeeeee;
  color: #5b5a5a;
}
.tag-item-outline-main {
  border-radius: 30px;
  color: #b91a22;
  border: #b91a22 1px solid;
  background-color: transparent;
  --min-size: 16;
  --max-size: 18;
  --font-size: calc((var(--min-size) * 1px) + (var(--max-size) - var(--min-size)) * ((100cqw - 320px) / (1920 - 320)));
  font-size: clamp(var(--min-size) * 1px, var(--font-size), var(--max-size) * 1px);
  padding: 0.75rem 1.35rem 0.3rem;
}

.radio_style {
  margin-top: 10px !important;
}
.radio_style input[type=radio] {
  display: none;
}
.radio_style input + label {
  position: relative;
  display: flex;
  align-items: center;
  line-height: 1;
  padding-left: 30px;
  margin-bottom: 0;
}
.radio_style input + label::before, .radio_style input + label::after {
  content: "";
  display: inline-block;
  border-radius: 50%;
}
.radio_style input + label::before {
  width: 20px;
  height: 20px;
  border: #cecece 1px solid;
  background: #fff;
  position: absolute;
  top: -1px;
  left: 0;
}
.radio_style input + label::after {
  width: 10px;
  height: 10px;
  background-color: transparent;
  position: absolute;
  left: 5px;
  top: 4px;
}
.radio_style input:checked + label::after {
  background-color: #b91a22;
}

.error-row {
  background: url("../images/error-background.svg") no-repeat;
  background-position: center top;
  max-width: 1400px;
  margin-left: auto;
  margin-right: auto;
  padding: 20px;
}
.error-row .img-box {
  max-width: 640px;
  margin-left: auto;
  margin-right: auto;
  position: relative;
  width: 100%;
}
.error-row .img-box img {
  display: block;
  width: 100%;
}
.error-row .img-box .error-code {
  color: #555;
  font-size: 120px;
  font-weight: bold;
  letter-spacing: 5px;
  line-height: 1;
  margin: 0;
  position: absolute;
  top: 42%;
  left: 50%;
  transform: translateX(-57%);
}
.error-row .content {
  margin-top: 50px;
  text-align: center;
}
.error-row .content .title {
  color: #444;
  font-size: 30px;
  font-weight: bold;
  margin: 0;
}
.error-row .content .des {
  margin-top: 25px;
}
.error-row .content .des p {
  color: #555;
  font-size: 18px;
  line-height: 1.6;
  margin: 0;
}
.error-row .content .des p:not(:last-child) {
  margin-bottom: 5px;
}
.error-row .btn-row {
  margin-top: 40px;
  margin-bottom: 20px;
}
.error-row .btn-row .link {
  border-radius: 30px;
  background: #666666;
  color: #fff;
  display: block;
  font-size: 24px;
  padding: 15px 13px 8px;
  margin-left: auto;
  margin-right: auto;
  text-align: center;
  text-decoration: inherit;
  text-transform: uppercase;
  transition: all 0.4s;
  width: 240px;
}
.error-row .btn-row .link:hover {
  background: #333;
}

@media (max-width: 1440px) {
  .error-row .img-box {
    max-width: 540px;
  }
  .error-row .img-box .error-code {
    font-size: 100px;
  }
  .error-row .btn-row {
    margin-top: 50px;
  }
}
@media (max-width: 1024px) {
  .error-row .img-box {
    max-width: 440px;
  }
  .error-row .img-box .error-code {
    font-size: 70px;
    top: 45%;
  }
  .error-row .btn-row {
    margin-top: 50px;
  }
}
@media (max-width: 640px) {
  .error-row .img-box .error-code {
    font-size: 50px;
    top: 45%;
  }
  .error-row .content {
    margin-top: 30px;
  }
  .error-row .content .title {
    font-size: 26px;
  }
  .error-row .content .des {
    margin-top: 15px;
  }
  .error-row .content .des p {
    font-size: 16px;
  }
  .error-row .btn-row {
    margin-top: 30px;
  }
  .error-row .btn-row .link {
    font-size: 17px;
    max-width: 170px;
  }
}
.btn:hover {
  background-color: #b91a22 !important;
  border-color: #b91a22 !important;
  color: #fff !important;
}

.btn:hover {
  background-color: #b91a22 !important;
  border-color: #b91a22 !important;
  color: #fff !important;
}

.btn {
  font-weight: 100;
  padding: 0.6em 2.1052631579vw 0.3em !important;
  border-radius: 0.5em !important;
  border-color: currentColor !important;
}
.btn.rounded-pill {
  border-radius: var(--bs-border-radius-pill) !important;
}
.btn.rounded-pill.active {
  border-color: transparent !important;
}
@media (max-width: 1024px) {
  .btn {
    font-size: 16px;
    font-size: 1rem;
    padding-left: 1em !important;
    padding-right: 1em !important;
  }
}
.btn .icon {
  vertical-align: top;
  margin-top: -3px;
  margin-right: 3px;
  margin-left: 2px;
}
.btn-wrap {
  flex-wrap: nowrap;
  justify-content: center;
  width: 100%;
  margin: 2rem 0 1rem;
}
.btn-wrap > li {
  flex: 1;
}
.btn-wrap > li + li {
  margin-left: 1.5rem;
}
@media (max-width: 1080px) {
  .btn-wrap {
    margin-top: 1rem;
    margin-bottom: 2rem;
  }
  .btn-wrap > li + li {
    margin-left: 0.5rem;
  }
}
.btn-skin {
  background-color: #dec9bc;
}
.btn-white {
  background-color: #fff;
}

.js-toggle-btn .btn {
  font-size: 16px;
  font-size: 1rem;
  border-radius: 20px;
  border: #eee 1px solid;
}
.js-toggle-btn .btn i {
  color: currentColor;
}
.js-toggle-btn .btn .icon {
  margin-top: 0;
}

.js-open {
  display: block !important;
}

.box {
  align-items: stretch;
  position: relative;
  z-index: 1;
  font-size: 18px;
  font-size: 1.125rem;
  padding: 10px;
}
.box p {
  margin-bottom: 0;
}
.box-head {
  flex: none;
  color: #717171;
  padding-left: 10px;
}
.box-text {
  flex: 1;
}

.card {
  flex-grow: 1;
  margin-left: 1em;
  margin-right: 1em;
  margin-bottom: 2em;
  border: #dbdbdb 1px solid;
  padding: 0.2631578947vw;
  border-radius: 10px;
  text-align: left;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.15);
  position: relative;
  overflow: hidden;
}
@media (min-width: 1025px) {
  .card__2 {
    flex: 0 0 calc(50% - 2em);
    max-width: calc(50% - 2em);
  }
}
@media (min-width: 1025px) {
  .card__3 {
    flex: 0 0 calc(33.3333333333% - 2em);
    max-width: calc(33.3333333333% - 2em);
  }
}
@media (min-width: 1025px) {
  .card__4 {
    flex: 0 0 calc(25% - 2em);
    max-width: calc(25% - 2em);
  }
}
@media (max-width: 1024px) {
  .card {
    margin-left: 0.5em;
    margin-right: 0.5em;
    flex: 0 0 calc(50% - 1em);
    max-width: calc(50% - 1em);
  }
}
.card__full {
  flex: 0 0 calc(100% - 2em) !important;
  max-width: calc(100% - 2em) !important;
}
@media (min-width: 1025px) {
  .card__horizontal {
    flex-direction: row;
  }
}
.card-img-top {
  border-radius: 10px;
  overflow: hidden;
}
.card-img-top img {
  display: block;
  width: 100%;
}
.card-time-date {
  font-size: 24px;
  font-size: 1.5rem;
}
@media (max-width: 1024px) {
  .card-time-date {
    font-size: 20px;
    font-size: 1.25rem;
  }
}
.card-time-year {
  font-size: 16px;
  font-size: 1rem;
  color: #8b8b8b;
}
@media (min-width: 1025px) {
  .card-time {
    margin-bottom: 1em;
  }
}
.card-title {
  --min-size: 18;
  --max-size: 18;
  --font-size: calc((var(--min-size) * 1px) + (var(--max-size) - var(--min-size)) * ((100cqw - 320px) / (1920 - 320)));
  font-size: clamp(var(--min-size) * 1px, var(--font-size), var(--max-size) * 1px);
  line-height: 1.5;
}
.card-item-wrap {
  justify-content: space-between;
}
@media (max-width: 1024px) {
  .card-item-wrap {
    margin-bottom: 10px;
  }
}
.card-item, .card-caption {
  color: #565656;
  --min-size: 15;
  --max-size: 16;
  --font-size: calc((var(--min-size) * 1px) + (var(--max-size) - var(--min-size)) * ((100cqw - 320px) / (1920 - 320)));
  font-size: clamp(var(--min-size) * 1px, var(--font-size), var(--max-size) * 1px);
}
.card-caption {
  padding: var(--bs-card-spacer-y) 0;
}
@media (min-width: 1025px) {
  .card-body {
    padding-bottom: 4em;
  }
}
.card-text {
  font-size: 16px;
  font-size: 1rem;
  color: #5b5a5a;
  font-weight: 100;
  line-height: 1.3;
}
.card-btn {
  --min-size: 13;
  --max-size: 16;
  --font-size: calc((var(--min-size) * 1px) + (var(--max-size) - var(--min-size)) * ((100cqw - 320px) / (1920 - 320)));
  font-size: clamp(var(--min-size) * 1px, var(--font-size), var(--max-size) * 1px) !important;
  transition: all 0.25s ease-in-out;
  padding-left: 4.6875vw !important;
  padding-right: 4.6875vw !important;
  padding-bottom: 1.0526315789vw !important;
  position: relative;
  top: 13%;
}
@media (min-width: 1025px) {
  .card-btn {
    position: absolute;
    top: -100%;
    padding-left: 1.0526315789vw !important;
    padding-right: 1.0526315789vw !important;
  }
}
.card-linkNormal {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  z-index: 1;
}
.card-wrap {
  width: 100%;
  margin-top: 1em;
  margin-bottom: 1em;
}
.card .icon {
  margin-top: -6px;
  margin-right: 3px;
}
@media (min-width: 1025px) {
  .card:hover .card-btn-wrap .card-btn {
    top: auto;
    bottom: calc(-100% - 1em);
    padding-bottom: 1em !important;
  }
}
@media (max-width: 1024px) {
  .card {
    margin-bottom: 1em;
  }
  .card-title {
    font-size: 15px;
    font-size: 0.9375rem;
    margin-top: 0.25em;
  }
  .card-body {
    padding: 1em 0.75em;
  }
  .card-caption {
    padding-top: 0.125em;
    padding-bottom: 0.25em;
  }
}

:root {
  --fancy-slider-theme-btn:#1b7cb9;
  --fancy-slider-theme-color: #fff;
  --fancy-slider-font-family: sans-serif;
  --fancy-slider-button-side-offset: 32px;
  --fancy-slider-button-mobile-side-offset: 12px;
  --fancy-slider-button-mobile-bottom-offset: 0px;
  --fancy-slider-head-font-size-s: 36px;
  --fancy-slider-head-font-size: 48px;
  --fancy-slider-head-font-size-b: 60px;
  --fancy-slider-title-font-size: 33px;
  --fancy-slider-title-font-family: "good-times", sans-serif;
  --fancy-slider-title-line-height: 1;
  /* background */
  --fancy-slider-transition-background-duration: 1000ms; /* duration background color */
  --fancy-slider-transition-background-delay: 1000ms; /* delay background color */
  /* other */
  --fancy-slider-transition-slide-delay: 500ms; /* delay slide translation */
  --fancy-slider-transition-title-delay: 850ms; /* delay title translation */
  --fancy-slider-transition-timing-function: cubic-bezier(0.5, 0, 0, 1); /* global timing function */
  /* scaling */
  --fancy-slider-transform-scale-slide: 0.6; /* before scale in slide - same as in js */
  --fancy-slider-transform-scale-img: 1.2; /* before scale in img - same as in js */
  /* nav */
  --fancy-slider-transition-button: 400ms; /*  */
  --fancy-slider-transition-button-arrow: 400ms; /*  */
  --fancy-slider-transition-button-circle: 400ms; /*  */
}

@keyframes jump {
  0% {
    bottom: 0;
  }
  50% {
    bottom: 10px;
  }
  100% {
    bottom: 0;
  }
}
@keyframes moveCircle {
  0% {
    transform: translateY(0%);
  }
  50% {
    transform: translateY(-5%);
  }
  100% {
    transform: translateY(0%);
  }
}
@keyframes opacity {
  0% {
    opacity: 0;
  }
  50% {
    opacity: 100%;
  }
  100% {
    opacity: 0;
  }
}
.index-content {
  padding-top: 1.5em;
  padding-bottom: 2em;
}
.index-content .heading-title-wrap {
  margin-top: 0.25em;
  margin-bottom: 2.6315789474vw;
}
@media (max-width: 1400px) {
  .index-content {
    padding-left: 1em;
    padding-right: 1em;
  }
}
@media (max-width: 1024px) {
  .index-content {
    padding: 1.5em 0.5em;
  }
  .index-content .heading-title-wrap {
    margin-top: 1em;
    margin-bottom: 1em;
  }
}
@media (max-width: 1024px) {
  .index-pageWrapper {
    width: 100%;
    justify-content: center;
  }
}
.index-pageCircle-wrap {
  top: -146px;
}
.index-pageDevelop {
  padding-top: 0.25em;
  padding-bottom: 6.3157894737vw;
}
.index-pageDevelop .index-card-wrap {
  margin-top: 2.6315789474vw;
}
@media (min-width: 1025px) {
  .index-pageDevelop .index-card-wrap .card-body {
    padding-bottom: 4em;
  }
}
.index-pageDigest {
  position: relative;
  z-index: 0;
}
.index-pageDigest .card {
  margin-left: 0;
  margin-right: 0;
  flex: 0 0 100% !important;
  max-width: 100% !important;
}
@media (min-width: 1025px) {
  .index-pageDigest-headWrap {
    position: absolute;
    left: 0;
    width: auto;
  }
  .index-pageDigest-headWrap .heading-title {
    transform: translateY(380px) rotate(90deg);
    position: relative;
  }
  .index-pageDigest-headWrap .heading-title-item {
    padding-top: 0.45em;
    transform: translate(60px, 250px) rotate(90deg);
    position: relative;
  }
  .index-pageDigest-headWrap .heading-title-item span {
    writing-mode: vertical-lr;
    position: relative;
    transform: rotate(-90deg);
  }
}
@media (min-width: 1101px) and (max-width: 1500px) {
  .index-pageDigest-headWrap {
    left: -1em;
  }
}
@media (min-width: 1025px) {
  .index-pageDigest-swiper {
    width: 100%;
    max-width: calc(100% - 6.25rem);
    margin-left: auto;
    margin-right: -10.5263157895vw;
  }
}
.index-pageDigest .swiper-pagination {
  bottom: -1.5em !important;
}
.index-pageDigest .swiper-pagination-bullet-active {
  background-color: #b91a22;
}
.index-pageInterview-wrap .card {
  box-shadow: none;
  border: none;
  align-items: flex-start;
  overflow: visible;
  justify-content: space-between;
  background-color: transparent;
  flex: 0 0 100%;
  max-width: 100%;
  padding-top: 1em;
}
.index-pageInterview-wrap .card-img-top {
  border-radius: 0;
  border-top-left-radius: 80px;
  border-bottom-right-radius: 80px;
  overflow: hidden;
}
.index-pageInterview-wrap .card-body {
  padding: 1.5em;
}
.index-pageInterview-wrap .card-title {
  --min-size: 20;
  --max-size: 30;
  --font-size: calc((var(--min-size) * 1px) + (var(--max-size) - var(--min-size)) * ((100cqw - 320px) / (1920 - 320)));
  font-size: clamp(var(--min-size) * 1px, var(--font-size), var(--max-size) * 1px);
  margin-bottom: 0.5em;
}
.index-pageInterview-wrap .card-des {
  line-height: 1.75;
}
@media (min-width: 1025px) {
  .index-pageInterview-wrap .card {
    flex-direction: row;
    padding-top: 5em;
  }
  .index-pageInterview-wrap .card-img-top {
    max-width: 630px;
    position: absolute;
    z-index: 1;
    top: 0;
  }
  .index-pageInterview-wrap .card-body {
    padding: 5.5em 6em 3em 4em;
    padding-left: 32em;
    max-width: calc(100% - 7em);
    margin-left: auto;
  }
}
@media (min-width: 1101px) and (max-width: 1500px) {
  .index-pageInterview-wrap .card-img-top {
    max-width: 25em;
  }
  .index-pageInterview-wrap .card-body {
    padding-left: 20em;
  }
}
.index-pageInterview-wrap::before {
  content: url("../images/img-circleHalf-01.png");
  position: absolute;
  right: 1em;
  z-index: 1;
}
@media (max-width: 1024px) {
  .index-pageInterview-wrap::before {
    right: 0;
  }
}
.index-pageInterview-wrap::after {
  content: url("");
  width: 100%;
  height: calc(50% - 4em);
  background-color: #e1e7ef;
  border-radius: 80px;
  position: absolute;
  z-index: 0;
}
@media (min-width: 1025px) {
  .index-pageInterview-wrap::after {
    width: calc(100% - 220px);
    height: calc(100% - 13em);
    left: 200px;
  }
}
.index-pageInterview-wrap::after, .index-pageInterview-wrap::before {
  top: 50%;
}
@media (min-width: 1025px) {
  .index-pageInterview-wrap::after, .index-pageInterview-wrap::before {
    top: 13em;
  }
}
.index-pageInterview .pageCircle {
  right: -100px;
  left: auto;
}
.index-pageInterview .index-pageCircle-wrap {
  top: 0;
}
.index-pageInterview-swiper {
  padding-top: 6em;
  overflow: hidden;
}
.index-pageInterview .swiper-button-lock, .index-pageInterview .swiper-pagination-lock {
  display: block;
}
.index-pageInterview .swiper-el-wrapper {
  display: flex;
  align-items: baseline;
  justify-content: space-around;
}
.index-pageInterview .swiper-el-wrapper .swiper-pagination {
  width: auto;
  position: static;
  color: #777;
}
.index-pageInterview .swiper-el-wrapper .swiper-pagination-current, .index-pageInterview .swiper-el-wrapper .swiper-pagination-total {
  font-family: Arial;
  padding-left: 5px;
  padding-right: 5px;
}
.index-pageInterview .swiper-el-wrapper .swiper-pagination-current {
  color: #b91a22;
  font-size: 1.5em;
}
.index-pageInterview .swiper-el-wrapper .swiper-button-prev,
.index-pageInterview .swiper-el-wrapper .swiper-button-next {
  position: static;
  color: #b91a22;
}
.index-pageInterview .swiper-el-wrapper .swiper-button-prev::after,
.index-pageInterview .swiper-el-wrapper .swiper-button-next::after {
  font-size: 1.25em;
}
@media (min-width: 1025px) {
  .index-pageInterview .swiper-el-wrapper {
    min-width: 10em;
    position: absolute;
    right: 8em;
    bottom: 2em;
  }
}
@media (min-width: 1025px) {
  .index-pageInterview {
    padding-top: 6.3157894737vw;
    padding-bottom: 6.3157894737vw;
  }
  .index-pageInterview::before, .index-pageInterview::after {
    content: "";
    display: block;
    width: 45px;
    height: 45px;
    background-color: #000;
    border-radius: 50%;
    position: absolute;
  }
  .index-pageInterview::before {
    left: 16.5%;
    background-color: #e0d6fd;
  }
  .index-pageInterview::after {
    left: 40%;
    bottom: 1%;
    background-color: #e4f1f8;
  }
  .index-pageInterview-headWrap {
    max-width: 27em;
    align-items: flex-start;
    position: absolute;
    top: 6em;
    right: calc(50% - 375px - 1.75em);
    z-index: 1;
  }
}
@media (min-width: 1401px) and (max-width: 1500px) {
  .index-pageInterview-headWrap {
    right: calc(50% - 325px + 4.5rem);
  }
}
@media (min-width: 1301px) and (max-width: 1400px) {
  .index-pageInterview-headWrap {
    right: calc(50% - 325px + 2.5rem);
  }
}
@media (min-width: 1101px) and (max-width: 1300px) {
  .index-pageInterview-headWrap {
    right: calc(50% - 325px - 0.5rem);
  }
}

.page-banner {
  width: 100%;
  overflow: hidden;
  z-index: 1;
}
@media (min-width: 900px) and (max-width: 1100px) {
  .page-banner {
    margin-top: 1%;
  }
}
.page-banner.index {
  background-repeat: no-repeat;
  background-position: top;
  background-size: auto;
  background-image: url("../images/index-banner-bg.jpg");
}
.page-banner.index .fancy-slider-container {
  display: flex;
  align-items: center;
}
@media (min-width: 1025px) {
  .page-banner.index .fancy-slider-container {
    padding-top: 3.5em;
    padding-bottom: 12em;
    max-width: 1480px;
  }
  .page-banner.index .fancy-slider-scale {
    position: relative;
    flex: 0 0 880px;
  }
}
@media (min-width: 1101px) and (max-width: 1500px) {
  .page-banner.index {
    background-size: 97em;
  }
  .page-banner.index .fancy-slider-container {
    max-width: 75em;
    padding-left: 2em;
    padding-right: 2em;
  }
  .page-banner.index .fancy-slider-scale {
    flex: 0 0 40em;
  }
}
@media (min-width: 1101px) and (max-width: 1300px) {
  .page-banner.index {
    background-size: 86em;
  }
  .page-banner.index .fancy-slider-scale {
    flex: 0 0 33em;
  }
}
@media (max-width: 1024px) {
  .page-banner.index {
    min-height: 46.875vw;
    padding: 0.75em 1em 1.5em;
    background-size: cover;
    margin-bottom: 2em;
  }
  .page-banner.index .fancy-slider-container {
    flex-direction: column;
  }
  .page-banner.index .fancy-slider-scale {
    order: -1;
    margin-bottom: 2.5em;
  }
}
@media (max-width: 640px) {
  .page-banner.main img {
    height: 125px;
    object-fit: cover;
  }
}
.page-scrollDown {
  position: absolute;
  height: 75px;
  margin: 2em;
  bottom: 5.5em;
  z-index: 200;
  left: 50%;
}
.page-scrollDown-btn {
  position: absolute;
  width: 120px;
  height: 72px;
  text-align: center;
  left: 50%;
  transform: translateX(-50%);
  animation: jump 1.5s infinite;
}
@media (max-width: 640px) {
  .page-scrollDown {
    display: none;
  }
}
.pageCircle {
  position: absolute;
  left: 150px;
  top: 0;
  animation: moveCircle 1.5s infinite;
}
.pageCircle-wrap {
  top: -146px;
}
@media (min-width: 1101px) and (max-width: 1500px) {
  .pageCircle {
    left: 4em;
  }
}
@media (max-width: 1024px) {
  .pageCircle {
    max-width: 10em;
    left: 0;
    z-index: -1;
  }
}

/* swiper */
.fancy-slider {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
  --fancy-slider-title-font-size: 88px;
}
.fancy-slider .swiper {
  width: 100%;
  height: 100%;
  transition-duration: var(--fancy-slider-transition-background-duration);
  transition-property: background-color;
  transition-delay: var(--fancy-slider-transition-background-delay);
}
.fancy-slider .swiper-wrapper {
  transition-delay: var(--fancy-slider-transition-slide-delay);
  transition-timing-function: var(--fancy-slider-transition-timing-function);
}
.fancy-slider .swiper-slide {
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
}

.fancy-slider-no-transition {
  transition-duration: 0ms !important;
  transition-delay: 0ms !important;
  transition-property: none !important;
}
.fancy-slider-no-transition *:not(.swiper-wrapper) {
  transition-duration: 0ms !important;
  transition-delay: 0ms !important;
  transition-property: none !important;
}
.fancy-slider-no-transition .swiper-wrapper {
  transition-delay: 0ms !important;
}

.fancy-slider-title {
  transition-delay: var(--fancy-slider-transition-title-delay);
  z-index: 10;
}
.fancy-slider-title-img {
  position: absolute;
  right: -14.5vw;
  z-index: -1;
  top: -1.5vw;
  opacity: 0;
}
.fancy-slider-title-img img {
  transform: scale(1) !important;
}
@media (min-width: 1025px) {
  .fancy-slider-title-img {
    width: 49.4736842105vw;
  }
}
@media (max-width: 640px) {
  .fancy-slider-title-img {
    display: none;
  }
}

.fancy-slider-scale img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}
@media (min-width: 1025px) {
  .fancy-slider-scale {
    width: 100%;
    height: 100%;
    position: absolute;
    opacity: 0.9;
    overflow: hidden;
  }
}

.fancy-slider-button {
  transition: var(--fancy-slider-transition-button);
  outline: none;
  position: absolute;
  width: 140px;
  z-index: 10;
  top: 50%;
  transform: translateY(-50%);
  cursor: pointer;
}
@media (min-width: 1025px) {
  .fancy-slider-button {
    top: 65%;
  }
}
@media (max-width: 1024px) {
  .fancy-slider-button {
    width: 100px;
  }
}
.fancy-slider-button svg {
  display: block;
  transition: var(--fancy-slider-transition-button);
}
.fancy-slider-button .fancy-slider-svg-circle-wrap {
  transition: var(--fancy-slider-transition-button-circle);
  transform-origin: -20px 40px;
  opacity: 1;
}
.fancy-slider-button circle {
  transition: var(--fancy-slider-transition-button-circle);
  stroke-width: 2px;
  stroke: #fff;
  fill: none;
  stroke-dasharray: 1;
  stroke-dashoffset: 1;
  opacity: 1;
  transform-origin: 0px 0px 0px;
}
.fancy-slider-button .fancy-slider-svg-arrow {
  transition: var(--fancy-slider-transition-button-arrow);
  fill: #fff;
  transform: rotateY(180deg) translate(-45px, 30.5px) scale(3);
}
.fancy-slider-button .fancy-slider-svg-wrap {
  transform: translateY(353px);
}
.fancy-slider-button .fancy-slider-svg-line {
  transition: var(--fancy-slider-transition-button);
  stroke: #fff;
  stroke-width: 2;
  transform: translate(50px, 42px);
}
.fancy-slider-button:not(.fancy-slider-button-disabled):hover circle {
  stroke-dasharray: 4px;
  stroke-dashoffset: 4px;
  opacity: 1;
}

.fancy-slider-button-prev {
  left: var(--fancy-slider-button-side-offset);
}
.fancy-slider-button-prev:not(.fancy-slider-button-disabled):hover svg {
  transform: translate(-16px);
}

.fancy-slider-button-next {
  right: var(--fancy-slider-button-side-offset);
}
.fancy-slider-button-next .fancy-slider-svg-wrap {
  transform: translateY(353px) rotateY(180deg);
  transform-origin: 80px 0px 0px;
}
.fancy-slider-button-next:not(.fancy-slider-button-disabled):hover svg {
  transform: translate(16px);
}

.fancy-slider-button-disabled {
  opacity: 0.2;
  cursor: default;
}

.fancy-slider .swiper {
  margin-left: auto;
  margin-right: auto;
  position: relative;
  overflow: hidden;
  list-style: none;
  padding: 0;
  z-index: 1;
}
.fancy-slider .swiper-vertical > .swiper-wrapper {
  flex-direction: column;
}
.fancy-slider .swiper-wrapper {
  position: relative;
  width: 100%;
  height: 100%;
  z-index: 1;
  display: flex;
  transition-property: transform;
  box-sizing: content-box;
  transform: translate(0);
}
.fancy-slider .swiper-android .swiper-slide {
  transform: translate(0);
}
.fancy-slider .swiper-pointer-events {
  touch-action: pan-y;
}
.fancy-slider .swiper-pointer-events.swiper-vertical {
  touch-action: pan-x;
}
.fancy-slider .swiper-slide {
  flex-shrink: 0;
  width: 100%;
  height: 100%;
  position: relative;
  transition-property: transform;
}
.fancy-slider .swiper-slide-invisible-blank {
  visibility: hidden;
}
.fancy-slider .swiper-autoheight {
  height: auto;
}
.fancy-slider .swiper-autoheight .swiper-slide {
  height: auto;
}
.fancy-slider .swiper-autoheight .swiper-wrapper {
  align-items: flex-start;
  transition-property: transform, height;
}
.fancy-slider .swiper-backface-hidden .swiper-slide {
  transform: translateZ(0);
  backface-visibility: hidden;
}
.fancy-slider .swiper-3d {
  perspective: 1200px;
}
.fancy-slider .swiper-3d .swiper-wrapper {
  transform-style: preserve-3d;
}
.fancy-slider .swiper-3d .swiper-slide {
  transform-style: preserve-3d;
}
.fancy-slider .swiper-3d .swiper-slide-shadow {
  transform-style: preserve-3d;
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  pointer-events: none;
  z-index: 10;
  background: rgba(0, 0, 0, 0.15);
}
.fancy-slider .swiper-3d .swiper-slide-shadow-left {
  transform-style: preserve-3d;
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  pointer-events: none;
  z-index: 10;
  background-image: linear-gradient(to left, rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0));
}
.fancy-slider .swiper-3d .swiper-slide-shadow-right {
  transform-style: preserve-3d;
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  pointer-events: none;
  z-index: 10;
  background-image: linear-gradient(to right, rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0));
}
.fancy-slider .swiper-3d .swiper-slide-shadow-top {
  transform-style: preserve-3d;
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  pointer-events: none;
  z-index: 10;
  background-image: linear-gradient(to top, rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0));
}
.fancy-slider .swiper-3d .swiper-slide-shadow-bottom {
  transform-style: preserve-3d;
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  pointer-events: none;
  z-index: 10;
  background-image: linear-gradient(to bottom, rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0));
}
.fancy-slider .swiper-3d .swiper-cube-shadow {
  transform-style: preserve-3d;
}
.fancy-slider .swiper-3d.swiper-css-mode .swiper-wrapper {
  perspective: 1200px;
}
.fancy-slider .swiper-css-mode > .swiper-wrapper {
  overflow: auto;
  scrollbar-width: none;
  -ms-overflow-style: none;
}
.fancy-slider .swiper-css-mode > .swiper-wrapper::-webkit-scrollbar {
  display: none;
}
.fancy-slider .swiper-css-mode > .swiper-wrapper > .swiper-slide {
  scroll-snap-align: start start;
}
.fancy-slider .swiper-horizontal.swiper-css-mode > .swiper-wrapper {
  scroll-snap-type: x mandatory;
}
.fancy-slider .swiper-vertical.swiper-css-mode > .swiper-wrapper {
  scroll-snap-type: y mandatory;
}
.fancy-slider .swiper-centered > .swiper-wrapper:before {
  content: "";
  flex-shrink: 0;
  order: 9999;
}
.fancy-slider .swiper-centered > .swiper-wrapper > .swiper-slide {
  scroll-snap-align: center center;
}
.fancy-slider .swiper-centered.swiper-horizontal > .swiper-wrapper > .swiper-slide:first-child {
  margin-inline-start: var(--swiper-centered-offset-before);
}
.fancy-slider .swiper-centered.swiper-horizontal > .swiper-wrapper:before {
  height: 100%;
  width: var(--swiper-centered-offset-after);
}
.fancy-slider .swiper-centered.swiper-vertical > .swiper-wrapper > .swiper-slide:first-child {
  margin-block-start: var(--swiper-centered-offset-before);
}
.fancy-slider .swiper-centered.swiper-vertical > .swiper-wrapper:before {
  width: 100%;
  height: var(--swiper-centered-offset-after);
}
@media (max-width: 480px) {
  .fancy-slider .fancy-slider {
    --fancy-slider-title-font-size: 60px;
  }
}
@media (max-width: 640px) {
  .fancy-slider .fancy-slider-button {
    top: auto;
    bottom: var(--fancy-slider-button-mobile-bottom-offset);
    transform: none;
  }
  .fancy-slider .fancy-slider-button-prev {
    left: var(--fancy-slider-button-mobile-side-offset);
  }
  .fancy-slider .fancy-slider-button-next {
    right: var(--fancy-slider-button-mobile-side-offset);
  }
}

.fancy-intro-wrap, .fancy-intro-head {
  color: #fff;
}
.fancy-intro-wrap {
  text-align: left;
}
@media (min-width: 1025px) {
  .fancy-intro-wrap {
    padding-right: 2.1052631579vw;
  }
}
.fancy-intro-head {
  --min-size: 22.5;
  --max-size: 45;
  --font-size: calc((var(--min-size) * 1px) + (var(--max-size) - var(--min-size)) * ((100cqw - 320px) / (1920 - 320)));
  font-size: clamp(var(--min-size) * 1px, var(--font-size), var(--max-size) * 1px);
}
.fancy-intro-title {
  --min-size: 18;
  --max-size: 30;
  --font-size: calc((var(--min-size) * 1px) + (var(--max-size) - var(--min-size)) * ((100cqw - 320px) / (1920 - 320)));
  font-size: clamp(var(--min-size) * 1px, var(--font-size), var(--max-size) * 1px);
  margin-top: 1.15rem;
  margin-bottom: 1.15rem;
}
@media (max-width: 1024px) {
  .fancy-intro-title {
    margin-top: 0.75rem;
    margin-bottom: 0.75rem;
  }
}
.fancy-intro-des {
  --min-size: 16;
  --max-size: 20;
  --font-size: calc((var(--min-size) * 1px) + (var(--max-size) - var(--min-size)) * ((100cqw - 320px) / (1920 - 320)));
  font-size: clamp(var(--min-size) * 1px, var(--font-size), var(--max-size) * 1px);
  line-height: 1.45;
}
.fancy-intro-btn .btn {
  color: #fff !important;
  border-color: var(--fancy-slider-theme-btn) !important;
  background-color: var(--fancy-slider-theme-btn);
  padding-top: 0.5em !important;
}
.fancy-intro-btn .btn .icon {
  margin-top: -2px;
}
@media (min-width: 1025px) {
  .fancy-intro-btn {
    margin-top: 2.75rem;
  }
}
@media (max-width: 1024px) {
  .fancy-intro-btn {
    margin-bottom: 5rem;
  }
}

.wave-wrap {
  position: absolute;
  width: 100%;
  bottom: 0;
  left: 0;
  z-index: 1;
}
@media (min-width: 1025px) {
  .wave-wrap {
    bottom: 3.5em;
  }
}

.waves {
  position: relative;
  width: 100%;
  height: 15vh;
  margin-bottom: -7px; /*Fix for safari gap*/
  min-height: 100px;
  max-height: 150px;
}

/* Animation */
.parallax > use {
  animation: move-forever 25s cubic-bezier(0.55, 0.5, 0.45, 0.5) infinite;
}

.parallax > use:nth-child(1) {
  animation-delay: -2s;
  animation-duration: 7s;
}

.parallax > use:nth-child(2) {
  animation-delay: -3s;
  animation-duration: 10s;
}

.parallax > use:nth-child(3) {
  animation-delay: -4s;
  animation-duration: 13s;
}

.parallax > use:nth-child(4) {
  animation-delay: -5s;
  animation-duration: 20s;
}

/*Shrinking for mobile*/
@media (max-width: 768px) {
  .waves {
    height: 40px;
    min-height: 40px;
  }
}
.article-onesetin {
  justify-content: space-between;
  padding: 5px 30px 20px 30px;
  width: 100%;
  border-bottom: 1px solid #cfcfcf;
}
@media (max-width: 1024px) {
  .article-onesetin {
    padding-left: 0.25em;
    padding-right: 0.25em;
  }
}
.article-txtcon {
  color: #000;
  font-weight: bold;
}
.article-txtcon-head {
  --min-size: 20;
  --max-size: 25;
  --font-size: calc((var(--min-size) * 1px) + (var(--max-size) - var(--min-size)) * ((100cqw - 320px) / (1920 - 320)));
  font-size: clamp(var(--min-size) * 1px, var(--font-size), var(--max-size) * 1px);
  margin-top: 0.5em;
  margin-left: 5px;
}
.article-author {
  text-align: right;
  margin-top: 15px;
}
.article-author .authorbox {
  margin-bottom: 1em;
}
@media (max-width: 1024px) {
  .article-author {
    width: 100%;
    margin-top: 0.25em;
  }
}
.articlecon {
  box-shadow: 0 9px 10px -8px rgba(0, 0, 0, 0.15);
  background-color: #fcfcfc;
  padding: 30px;
  padding-right: 25px;
  line-height: 1.7;
}

.authorbox {
  --min-size: 13;
  --max-size: 13;
  --font-size: calc((var(--min-size) * 1px) + (var(--max-size) - var(--min-size)) * ((100cqw - 320px) / (1920 - 320)));
  font-size: clamp(var(--min-size) * 1px, var(--font-size), var(--max-size) * 1px);
  display: inline-block;
  color: #fff;
  background: #1b7cb9;
  border-radius: 5px;
  padding: 9px 18px 6px;
}

.datebox {
  --min-size: 14;
  --max-size: 16;
  --font-size: calc((var(--min-size) * 1px) + (var(--max-size) - var(--min-size)) * ((100cqw - 320px) / (1920 - 320)));
  font-size: clamp(var(--min-size) * 1px, var(--font-size), var(--max-size) * 1px);
  margin-bottom: 0;
}

.otherarticle {
  justify-content: space-between;
  padding: 30px 20px;
}
@media (min-width: 1025px) {
  .otherarticle {
    flex-wrap: nowrap;
  }
  .otherarticle .prenextbox {
    padding-right: 4em;
  }
  .otherarticle .btn-wrap {
    width: auto;
    margin: 0;
  }
}

.prenext-list {
  display: flex;
  font-size: 17px;
  margin-bottom: 20px;
}
.prenext-list-head {
  flex-shrink: 0;
}
.prenext-list a {
  display: inline-block;
  color: #000;
  font-weight: 600;
}

/*=============== VARIABLES CSS ===============*/
:root {
  --active-color: hsl(200, 70%, 56%);
  --error-color: hsl(353, 64%, 53%);
  --font-size-smaller: 0.875rem;
  --form-mb: 17px ;
}

.form__error,
.form__correct {
  position: absolute;
  right: 1rem;
  top: 50%;
  transform: translateY(-50%);
  display: flex;
  align-items: center;
  column-gap: 0.25rem;
  opacity: 0;
  pointer-events: none;
  transition: opacity 0.4s;
}

.form__error i,
.form__correct i {
  font-size: 1rem;
}

.form__error span,
.form__correct span {
  font-size: var(--font-size-smaller);
  font-weight: 500;
}

.form__correct {
  color: var(--active-color);
}

.form__error {
  color: var(--error-color);
}

/* Invalid field color */
.valid .form__correct,
.invalid .form__error {
  opacity: 1;
}

.contact-bg {
  background-repeat: no-repeat;
  background-position: top left;
  background-size: auto;
  background-image: url("../images/contact_bgInfo.png");
}
@media (min-width: 1025px) {
  .contact-bg {
    padding-bottom: 120px;
  }
}
@media (min-width: 1251px) and (max-width: 1450px) {
  .contact-bg {
    padding-bottom: 140px;
  }
}
@media (max-width: 1080px) {
  .contact-bg {
    background-size: 100%;
    padding-bottom: 0;
  }
}
.contact-wrapper {
  position: relative;
  padding: 1.5em 2em 1.5em 1.5em;
  background-color: #f8f8f8;
}
@media (max-width: 1024px) {
  .contact-wrapper {
    padding-right: 1.5em;
  }
}
.contact-info-list {
  background-color: #fff;
  padding: 1.25em 1.2em 1em;
}
.contact-info-list:not(:last-child) {
  margin-bottom: 1em;
}
.contact-info-head {
  padding-left: 20px;
  line-height: 1.75;
}
.contact-info-headTitle {
  --min-size: 17;
  --max-size: 18;
  --font-size: calc((var(--min-size) * 1px) + (var(--max-size) - var(--min-size)) * ((100cqw - 320px) / (1920 - 320)));
  font-size: clamp(var(--min-size) * 1px, var(--font-size), var(--max-size) * 1px);
  color: #020202;
  margin-bottom: 0.35em;
}
.contact-info-headsubTitle {
  --min-size: 16;
  --max-size: 16;
  --font-size: calc((var(--min-size) * 1px) + (var(--max-size) - var(--min-size)) * ((100cqw - 320px) / (1920 - 320)));
  font-size: clamp(var(--min-size) * 1px, var(--font-size), var(--max-size) * 1px);
  color: #5d5d5e;
  line-height: 1.1;
}
.contact-info-txt {
  width: 100%;
  background-color: #fff;
  padding: 1.75em 1.5em 3em;
  margin-bottom: 1em;
}
.contact-info-txtList {
  display: flex;
  align-items: flex-start;
}
.contact-info-txtList .icon {
  margin-right: 13px;
  margin-top: -2px;
}
@media (max-width: 1024px) {
  .contact-info-txt {
    padding: 1em;
  }
}
.contact-btn-wrap .contact-btn-api .g-recaptcha {
  display: flex;
  align-items: center;
}
@media (max-width: 1024px) {
  .contact-btn-wrap .contact-btn-api .g-recaptcha {
    justify-content: center;
  }
}
@media (min-width: 1025px) {
  .contact-btn-wrap .contact-btn-api {
    padding-right: 1em;
  }
}
.contact-btn-wrap .btn-wrap {
  flex-wrap: wrap;
  margin-bottom: 0;
}
.contact-btn-wrap .btn-wrap .btn {
  background-color: #fff;
}
@media (min-width: 1025px) {
  .contact-btn-wrap .btn-wrap {
    width: auto;
    margin-top: 0;
  }
  .contact-btn-wrap .btn-wrap .btn {
    line-height: 1.5;
    padding-left: 3rem !important;
    padding-right: 3rem !important;
    padding-top: 1rem !important;
    padding-bottom: 0.5rem !important;
  }
}
@media (max-width: 1024px) {
  .contact-btn-wrap .btn-wrap {
    display: block;
  }
  .contact-btn-wrap .btn-wrap .btn {
    width: 100%;
  }
}
@media (min-width: 1025px) {
  .contact-btn-wrap {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
}
@media (max-width: 1024px) {
  .contact-btn-wrap {
    padding-top: 1rem;
    padding-bottom: 0.75rem;
  }
}
.contact-form {
  background: #fff;
  padding: 1.75em 2.5em 1.5em;
}
.contact-form .radio_style {
  margin-top: 0 !important;
}
.contact-form legend {
  margin-bottom: 0.75em;
}
.contact-form .legend {
  color: #222222;
  border-bottom: #bfbfbf 1px solid;
  padding-bottom: 20px;
  margin-bottom: 50px;
}
.contact-form .legend-title {
  color: #b91a22;
  --min-size: 18;
  --max-size: 24;
  --font-size: calc((var(--min-size) * 1px) + (var(--max-size) - var(--min-size)) * ((100cqw - 320px) / (1920 - 320)));
  font-size: clamp(var(--min-size) * 1px, var(--font-size), var(--max-size) * 1px);
  line-height: 1.5;
  margin-bottom: 15px;
}
.contact-form .legend-subtitle {
  --min-size: 16;
  --max-size: 18;
  --font-size: calc((var(--min-size) * 1px) + (var(--max-size) - var(--min-size)) * ((100cqw - 320px) / (1920 - 320)));
  font-size: clamp(var(--min-size) * 1px, var(--font-size), var(--max-size) * 1px);
}
.contact-form .legend-txt {
  --min-size: 15;
  --max-size: 18;
  --font-size: calc((var(--min-size) * 1px) + (var(--max-size) - var(--min-size)) * ((100cqw - 320px) / (1920 - 320)));
  font-size: clamp(var(--min-size) * 1px, var(--font-size), var(--max-size) * 1px);
}
@media (max-width: 1024px) {
  .contact-form .legend {
    padding-bottom: 0.5em;
    margin-bottom: 1.5em;
  }
  .contact-form .legend-title {
    margin-bottom: 0;
  }
}
.contact-form .form-control {
  appearance: auto;
  padding: 10px 5px 5px;
  background-color: #fff;
}
.contact-form .form-control, .contact-form .form-control:hover, .contact-form .form-control:focus {
  border: none;
  outline: none;
  box-shadow: none;
  border-radius: 0;
}
.contact-form .form-control:hover, .contact-form .form-control:focus {
  border-color: #b91a22;
}
.contact-form .form-control.form-border {
  border: #c9c9c9 1px solid;
  border-radius: 6px;
}
@media (max-width: 1024px) {
  .contact-form .form-control.form-border {
    width: 100% !important;
    flex: 1 !important;
  }
}
.contact-form .form-group {
  display: flex;
  border: #dcd9d9 1px solid;
  padding: 5px 10px;
  border-radius: 5px;
  margin-bottom: var(--form-mb);
}
@media (max-width: 1024px) {
  .contact-form {
    width: calc(100% - 1.5em);
    margin-left: 0.75em;
    margin-right: 0.75em;
    margin-top: 1em;
    padding: 1.5em;
  }
}

@media (min-width: 1025px) {
  .form-flex {
    align-items: center;
    margin-bottom: var(--form-mb);
  }
}
@media (max-width: 1080px) {
  .form-flex {
    flex-direction: column;
  }
}
.form-col {
  flex: 1;
}
@media (min-width: 1025px) {
  .form-col-left {
    flex: 1.6;
    padding-right: 15px;
  }
}
.form-col-flex {
  display: inline-flex;
}

@media (min-width: 1025px) {
  .recurit-page-wrapper .contact-form {
    padding-right: 4.5em;
  }
}

.contact-page-wrapper .contact-form {
  padding: 1.75em;
}
@media (min-width: 1025px) {
  .contact-page-wrapper .contact-form {
    margin-top: -180px;
    right: 60px;
  }
  .contact-page-wrapper .contact-form .legend {
    padding-left: 15px;
  }
}
@media (max-width: 810px) {
  .contact-page-wrapper .contact-form textarea.form-control {
    height: 10em;
  }
}
@media (min-width: 1025px) {
  .contact-page-wrapper .contact-info {
    padding-top: 3.1578947368vw;
    padding-left: 2.6315789474vw;
  }
}