/*=============== VARIABLES CSS ===============*/
:root {
    --active-color: hsl(200, 70%, 56%);
    --error-color: hsl(353, 64%, 53%);
    --font-size-smaller: 0.875rem;
    --form-mb: 17px
}

.form__error,
.form__correct {
    position: absolute;
    right: 1rem;
    top: 50%;
    transform: translateY(-50%);
    display: flex;
    align-items: center;
    column-gap: .25rem;
    opacity: 0;
    pointer-events: none;
    transition: opacity .4s;
}
  
.form__error i,
.form__correct i {
    font-size: 1rem;
}
  
.form__error span,
.form__correct span {
    font-size: var(--font-size-smaller);
    font-weight: 500;
}

.form__correct{
    color: var(--active-color)
}

.form__error{
    color: var(--error-color);
}

/* Invalid field color */
.valid .form__correct,
.invalid .form__error {
    opacity: 1;
}

// 聯絡我們
.contact{
    &-bg{
        @include setBackSet(top left,auto);
        background-image: url('../images/contact_bgInfo.png');
        @include setMinRwd{
            padding-bottom: 120px;
        }

        @include setWidthRwd(1251px,1450px){
            padding-bottom: 140px;
        }

        @include pad1080{
            background-size: 100%;
            padding-bottom: 0;
        }
    }

    &-wrapper{
        position: relative;
        padding: 1.5em 2em 1.5em 1.5em;
        background-color: #f8f8f8;
        @include setMaxRwd{
            padding-right: 1.5em;
        }
    }
    
    // 基本資訊使用
    &-info{
        &-list{
            background-color: #fff;
            padding: 1.25em 1.2em 1em;
            &:not(:last-child){
                margin-bottom: 1em;
            }
        }

        &-icon{

        }

        &-head{
            padding-left: 20px;
            line-height: 1.75;

            &Title{
                --min-size: 17;
                --max-size: 18;
                --font-size: calc((var(--min-size) * 1px) + (var(--max-size) - var(--min-size)) * ((100cqw - 320px) / (1920 - 320)));
                font-size: clamp(var(--min-size) * 1px, var(--font-size), var(--max-size) * 1px);
                color: #020202;
                margin-bottom: 0.35em;
            }

            &subTitle{
                --min-size: 16;
                --max-size: 16;
                --font-size: calc((var(--min-size) * 1px) + (var(--max-size) - var(--min-size)) * ((100cqw - 320px) / (1920 - 320)));
                font-size: clamp(var(--min-size) * 1px, var(--font-size), var(--max-size) * 1px);
                color: #5d5d5e;
                line-height: 1.1;
            }
        }

        &-txt{
            width: 100%;
            background-color: #fff;
            padding: 1.75em 1.5em 3em;
            margin-bottom: 1em;

            &List{
                display: flex;
                align-items: flex-start;
                .icon{
                    margin-right: 13px;
                    margin-top: -2px;
                }
            }

            @include setMaxRwd{
                padding: 1em;
            }
        }
    }

    &-btn-wrap{
        .contact-btn-api{
            .g-recaptcha{
                display: flex;
                align-items: center;
                @include setMaxRwd{
                    justify-content: center;
                }
            }

            @include setMinRwd{
                padding-right: 1em;
            }
        }

        .btn-wrap{
            flex-wrap: wrap;
            margin-bottom: 0;

            .btn{
                background-color: #fff;
            }

            @include setMinRwd{
                width: auto;
                margin-top: 0;
                .btn{
                    line-height: 1.5;
                    padding-left: 3rem !important;
                    padding-right: 3rem !important;
                    padding-top: 1rem !important;
                    padding-bottom: 0.5rem !important;
                }
            }

            @include setMaxRwd{
                display: block;

                .btn{
                    width: 100%;
                }
            }
        }

        @include setMinRwd{
            display: flex;
            align-items: center;
            justify-content: space-between;
        }

        @include setMaxRwd{
            padding-top: 1rem;
            padding-bottom: 0.75rem;
        }
    }

    // 表單使用
    &-form {
        background: #fff;
        padding: 1.75em 2.5em 1.5em;

        .radio_style{
            margin-top: 0 !important;
        }

        legend{
            margin-bottom: 0.75em;
        }
        
        .legend{
            color:$colorBase;
            border-bottom: #bfbfbf 1px solid;
            padding-bottom: 20px;
            margin-bottom: 50px;

            &-title{
                color: $colorMain;

                --min-size: 18;
                --max-size: 24;
                --font-size: calc((var(--min-size) * 1px) + (var(--max-size) - var(--min-size)) * ((100cqw - 320px) / (1920 - 320)));
                font-size: clamp(var(--min-size) * 1px, var(--font-size), var(--max-size) * 1px);

                line-height:1.5;
                margin-bottom: 15px;
            }

            &-subtitle{
                --min-size: 16;
                --max-size: 18;
                --font-size: calc((var(--min-size) * 1px) + (var(--max-size) - var(--min-size)) * ((100cqw - 320px) / (1920 - 320)));
                font-size: clamp(var(--min-size) * 1px, var(--font-size), var(--max-size) * 1px);
            }

            &-txt{
                --min-size: 15;
                --max-size: 18;
                --font-size: calc((var(--min-size) * 1px) + (var(--max-size) - var(--min-size)) * ((100cqw - 320px) / (1920 - 320)));
                font-size: clamp(var(--min-size) * 1px, var(--font-size), var(--max-size) * 1px); 
            }

            @include setMaxRwd{
                padding-bottom: 0.5em;
                margin-bottom: 1.5em;
                &-title{
                    margin-bottom: 0;
                }
            }
        }

        .form-control{
            appearance: auto;
            padding: 10px 5px 5px;
            background-color: #fff;
            
            &,
            &:hover,
            &:focus{
                border: none;
                outline: none;
                box-shadow: none;
                border-radius: 0;
            }

            &:hover,
            &:focus{
                border-color: $colorMain;
            }

            &.form-border{
                border:#c9c9c9 1px solid;
                @include radius(6px);

                @include setMaxRwd{
                    width: 100% !important;
                    flex: 1 !important;
                }
            }
        }

        .form-group{
            display: flex;
            border: #dcd9d9 1px solid;
            padding: 5px 10px;
            @include radius(5px);
            margin-bottom: var(--form-mb);

            // & > label{
            //     position: relative;
            //     color: #5d5d5e;
            //     margin-bottom: 0px;

            //     @include setMinRwd{
            //         flex: none;
            //         min-width: 150px;
            //         padding-right: 10px;
            //         &::after{
            //             content: "";
            //             display: block;
            //             @include setSize(1px,17px);
            //             background-color: #b3b3b3;
            //             position: absolute;
            //             right: 0;
            //             top: 50%;
            //             transform: translateY(-50%);
            //         }
            //     }

            //     @include setWidthRwd(1100px,1400px){
            //         min-width: 100px;
            //     }

            //     @include setMaxRwd{
            //         margin-bottom: 0.35em;
            //     }
            // }

            // &-input{
            //     flex: 1;
            // }

            // @include pad1080{
            //     flex-direction: column;
            // }
        }

        @include setMaxRwd{
            width: calc(100% - 1.5em);
            margin-left: calc(1.5em / 2);
            margin-right: calc(1.5em / 2);
            margin-top: 1em;
            padding: 1.5em;
        }
    }
}

.form{
    // 格線設定
    &-flex{
        @extend %setFlex;
        @include setMinRwd{
            align-items: center;
            margin-bottom: var(--form-mb);
        }

        @include pad1080{
            flex-direction: column;
        }
    }
    
    // 格線調整
    &-col{
        flex: 1;
        
        &-left{
            @include setMinRwd{
                flex: 1.6;
                padding-right: 15px;
            }
        }

        &-flex{
            display: inline-flex;
        }
    }
}

// 主項目 : 人才
.recurit-page-wrapper{
    .contact-form{
        @include setMinRwd{
            padding-right: 4.5em;
        }
    }
}

// 主項目 : 聯絡我們
.contact-page-wrapper{
    .contact-form{
        @extend .boxShadow;
        padding: 1.75em;

        @include setMinRwd{
            margin-top: -180px;
            right: 60px;
            .legend{
                padding-left: 15px;
            }
        }

        @include pad{
            textarea.form-control{
                height: 10em;
            }
        }
    }

    .contact-info{
        @include setMinRwd{
            padding-top: get-vw(60px);
            padding-left: get-vw(50px);
        }
    }
}