// 按鈕Hover的狀態
%btnMainHover{
    background-color: $colorMain !important;
    border-color: $colorMain !important;
    color: #fff !important;
}

%btnMainHover,
%btnoutlineHover{
    background-color: $colorMain !important;
    border-color: $colorMain !important;
    color: #fff !important;
}
%btnItemHover{
    background-color: $colorItem !important;
    border-color: $colorItem !important;
    color: #fff !important;
}

// 按鈕樣式
.btn{
    font-weight: 100;
    padding: 0.6em get-vw(40px) 0.3em !important;
    border-radius: 0.5em !important;
    border-color: currentColor !important;
    &.rounded-pill{
      border-radius: var(--bs-border-radius-pill) !important;
      &.active{
        border-color: transparent !important;
      }
    }

    &:hover{
        @extend %btnMainHover;
    }

    @include setMaxRwd{
        @include fontSize(16px);
        padding-left: 1em !important;
        padding-right: 1em !important;
    }

    .icon {
        vertical-align: top;
        margin-top: -3px;
        margin-right: 3px;
        margin-left: 2px;
    }

    // 包按鈕的框
    &-wrap {
        @extend %setFlex-center;
        flex-wrap: nowrap;
        justify-content: center;
        width: 100%;
        margin: 2rem 0 1rem;
        &>li {
            flex: 1;
            &+li {
                margin-left: 1.5rem;
            }
        }

        @include pad1080 {
            margin-top: 1rem;
            margin-bottom: 2rem;
            &>li {
                &+li {
                    margin-left: 0.5rem;
                }
            }
        }
    }

    // 顏色
    &-skin{
        background-color: #dec9bc;
    }

    &-white{
        background-color: #fff;
    }
}

.js-toggle-btn{
    .btn{
        @include fontSize(16px);
        @include radius(20px);
        border: #eee 1px solid;
        i{
            color: currentColor;
        }

        .icon{
            margin-top: 0;
        }
    }
}

.js-open{
    display: block !important;
}