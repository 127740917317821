footer {
  @include setBackSet(top,cover);
  background-image: url('../images/footer-bg.png');
  padding-top: get-vw-mobile(25px);

  .footer {
    &-top{
      display: flex;
      padding-top: 45px;
      padding-bottom: 40px;

      @include setWidthRwd(1101px,1600px){
        padding: 1em 2em;
      }

      @include setMaxRwd{
        padding: 1em;
      }
    }

    //== 項目 ==//
    // &-title{
    //   margin-bottom: 0.25em;
    //   font-weight: 900;

    //   @include setMaxRwd{
    //     font-size: 2em;
    //   }
    // }

    &-head{
      @include setMaxRwd{
        text-align: center;
        margin-top: 10px;
        margin-bottom: 20px;
      }
    }

    &-info{
      @include fontSize(15px);
      font-weight: 100;
      p{
        margin-bottom: 0;
      }

      .icon{
        margin-top: -5px;
        margin-right: 4px;
      }

      &-logo {
        img {
            display: block;
            max-width: 100%;
        }

        @include setMaxRwd{
          margin-bottom: 1.5rem;
        }
      }

      &-wrap{
        @extend %setFlex-center;
        justify-content: space-between;
        flex-direction: column;
        width: 100%;
      }

      //
      &-center{
        width: 100%;
        .footer-info{
          .item{
            position: relative;
            margin-top: 0.5rem;
            padding-left: 3.75rem;
            .icon{
              position: absolute;
              left:0
            }
            
            &-txt{
              --min-size: 18;
              --max-size: 20;
              --font-size: calc((var(--min-size) * 1px) + (var(--max-size) - var(--min-size)) * ((100cqw - 320px) / (1920 - 320)));
              font-size: clamp(var(--min-size) * 1px, var(--font-size), var(--max-size) * 1px);
              line-height: 1.25;
              span{
                color: #818282;
                font-family: $Roboto, $fontNotosanc-tc;
                --min-size: 16;
                --max-size: 18;
                --font-size: calc((var(--min-size) * 1px) + (var(--max-size) - var(--min-size)) * ((100cqw - 320px) / (1920 - 320)));
                font-size: clamp(var(--min-size) * 1px, var(--font-size), var(--max-size) * 1px);
                padding-top: 5px;
              }
            }

            @include mobile{
              text-align: center;
              margin-top: 1.5em;
              &:not(:last-child){
                margin-bottom: 3em;
              }

              .icon{
                top: 50%;
                transform: translateY(-50%);
              }

              &-txt{
                display: flex;
                align-items: center;
                span{
                  flex: 1;
                  text-align: left;
                  padding-left: 10px;
                  padding-top: 0;
                }
              }
            }
          }
        }

        @include setMinRwd{
          flex: 1;
          padding: 1vw 2vw 0;
          .footer-info{
            display: flex;
            flex-flow: row wrap;
            justify-content: space-between;
            .item{
              & ~ .item{
                margin-left: 1.25rem;
              }
            }
          }
        }
      }

      // 
      &-bottom {
        padding-top: get-vw-mobile(15px);
        border-top: #c6d0d5 1px solid;
        @include setMinRwd{
          padding-top: get-vw(20px);
          .footer-info{
            display: flex;
            align-items: center;
            p{
              & ~ p{
                margin-left: 10px;
              }
            }
          }
        }

        @include setMaxRwd{
          .iware{
            display: inline-block;
            margin-top: 5px;
          }
        }
      }
    }
  }

  @include setMinRwd{
    padding-top: get-vw(225px);
  }

  @include setMaxRwd{
    margin-bottom: 2em;
  }
}

// 給予區塊的左右側線條使用
.divider {
  width: 2px;
  height: 100%;
  background: #bfb1a5;
  @include setWidthRwd(800px,1000px){
    display: none;
  }
}

.copyright{
  --min-size: 13;
  --max-size: 14;
  --font-size: calc((var(--min-size) * 1px) + (var(--max-size) - var(--min-size)) * ((100cqw - 320px) / (1920 - 320)));
  font-size: clamp(var(--min-size) * 1px, var(--font-size), var(--max-size) * 1px);
  font-family: $Roboto;
}

.iware {
  font-size: 87%;
  font-weight: 100;
  font-weight: lighter;
  margin-bottom: 0 !important;
  padding: 4px 8px;
  &,
  a{
    color:#aaaaaa;
  }

  a {
      &:hover {
          text-decoration: underline;
      }
  }

  b{
    font-weight: 100;
  }
}

// Gotop 使用
.float-link {
  right: -100%;
  position: fixed;
  bottom: 200px;
  z-index: 10;
  transition: 0.3s;
  .link-btn {
    a {
      &:hover {
          opacity: 0.65;
      }
    }

    &.nabar-icon-mobile{
      @include setMinRwd{
        display: none;
      }
      img{
        max-width: 100%;
      }
    }

    &.top{
      @include setMaxRwd{
        display: none;
      }
    }
  }

  &.active {
      right: 15px;
  }

  @include setStyleRwd(1600px){
    &.active {
        right: 10px;
    }
  }

  @include setMaxRwd{
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    bottom: 0;
    right: 0 !important;
    background: $colorMain;
    padding: 5px;
    margin: 0;
    .link-btn {
      text-align: center;
      margin: 0;

      &,
      .link-btn-toggle{
        display: block;
        width: 100%;
      }

      .link-btn-toggle{
        color: #fff;
      }

      .icon{
        small{
          font-size: 100%;
        }
      }
    }
  }
}

// Cookie Privacy
.cookies {
  display: none;
  width: 100%;
  background: #303030;
  color: #fff;
  @include fontSize(16px);
  padding: 1.75rem 1rem 1.75rem;
  position: fixed;
  bottom: 0;
  left: 0;
  z-index: 60;
  
  p {
    font-weight: 400;
    text-align: center;
    margin-bottom: 0.5rem;

    &:last-child{
      margin-bottom: 0;
    }

    @include setMinRwd{
      text-align: left;
    }
  }

  .btn {
    text-decoration: none !important;
    border-radius: 10px !important;

    @include setStyleMinRwd(800px){
      padding-top: 0.125rem;
      padding-bottom: 0.125rem;
    }

    @include mobile{
      display: block;
      width: 100%;
      @include fontSize(16px);
    }
  }

  &-wrapper{
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    @include setMaxRwd{
      flex-direction: column;
    }
  }
  
  &.display {
    display: block;
    animation: cookies 1s 1;
  }

  &.close-cookies {
    transition: all 0.2s ease-out;
    transform: translate3d(0, 100%, 0);
    @include pad {
      bottom: -2%;
    }
  }

  @include setMinRwd{
    .btn {
      @include fontSize(18px);
      margin-left: 2.5em;
      margin-bottom: 0.25em;
      padding: 0.5rem 2.5rem;
    }
  }

  @include pad {
    width: calc(100% - 1em);
    border-top: none;
    margin: .5em;
    padding-top: 1rem;
    padding-bottom: 1rem;
    @include fontSize(14px);
    
    &.display { 
      flex-direction: column;
    }
  }
}

@keyframes cookies {
  0% {
    transform: translate3d(0, 100%, 0);
  }
  100% {
    transform: translate3d(0, 0%, 0);
  }
}