/* #Navigation
================================================== */
.start-header {
	opacity: 1;
	transform: translateY(0);
	transition : all 0.3s ease-out;
    &.scroll-on {
        position: fixed;
        box-shadow: 0 5px 10px 0 rgba(138, 155, 165, 0.15);
        padding: 10px 0;
        transition : all 0.3s ease-out;
        @include setMinRwd{
            .navbar-brand{
                img{
                    transition : all 0.3s ease-out;
                }
            }
            .search-formList{
               top:  105px;
            }
        }
    }
    .container{
        max-width: 1870px;
        padding-right: 0;
        margin-right: 0;
        img{
            margin: 0 !important;
        }
    }
}

.navigation-wrap{
	width: 100%;
	top: 0;
	left: 0;
	z-index: 1050;
	transition : all 0.3s ease-out;
    border-bottom: #495867 5px solid;
}

.navbar{
    align-items: stretch;
	padding: 0;

    @include setWidthRwd(1050px,1600px){
        flex-wrap: wrap;
        justify-content: center;
    }

    @include setWidthRwd(1050px,1500px){
        .nav-link{
            @include fontSize(16px)
        }

        .nav-item-search{
            .nav-link{
                padding-top: 8px !important;
            }
        }
        
    }

    @include setWidthRwd(1050px,1300px){
        .nav-item-search{
            .nav-link {
                padding-top: 6px !important;
            }
        }
    }

    @include setWidthRwd(1050px,1150px){
        flex-direction: column;
    }

    @include setWidthRwd(1000px,1020px){
        align-items: flex-start;
    }

    @include pad1080{
        justify-content: space-between;
    }

    // LOGO
    &-brand{
        padding: 0;
        margin: 0;
        img{
            display: block;
            transition : all 0.3s ease-out;
        }
        
        @include setMinRwd{
            margin-bottom: 0.15rem;
        }

        @include setStyleMinRwd(1600px){
            margin-right: 3em;
        }

        @include setWidthRwd(1200px,1300px){
            max-width: 12.5em;
        }

        @include pad1080{
            max-width: 13em;
            margin-left: -0.5rem;
        }
    }

    // 
    &-light{
        .navbar-toggler-icon {
            width: 28px;
            height: 17px;
            background-image: none;
            position: relative;
            border-bottom: 1px solid #000;
            transition: all 300ms linear;
            &::after, 
            &::before{
                width: 28px;
                position: absolute;
                height: 1px;
                background-color: #000;
                top: 0;
                left: 0;
                content: '';
                z-index: 2;
                transition: all 300ms linear;
            }
            &::after{
                top: 8px;
            }
        }
        .navbar-nav{
            .nav-link {
                display: inline-flex;
                white-space: normal;
            }
        }
    }

    // 主連結
    &-collapse{
        flex-wrap: wrap;
        align-items: stretch;
        justify-content: space-between;

        @include setMinRwd{
            flex-wrap: nowrap;
        }

        @include setWidthRwd(800px,1500px){
            width: 100%;
            margin-top: 0;
        }

        @include pad1080{
            position: fixed;
            @include setSize(100%,100vh);
            top: 0;
            left: -100%;
            background: #fff;
            transition: 0.15s;
            overflow-y: auto;
            max-width: 60vw;
            &,
            &>ul{
                z-index: 100;
            }

            &>ul{
                position: relative;
                justify-content: flex-start !important;
                // min-height: 100%;
            }

            &.show{
                display: block !important;
                left: 0;
            }
        }
    }

    &-overlay{
        display: block;
        @include setSize(100%,100vh);
        background-color: rgba(0,0,0,0.75);
        position: fixed;
        top: 0;
        left: 0;
        z-index: 99;
        opacity: 0;
        visibility: hidden;

        &.active{
            opacity: 1;
            visibility: visible;
        }

        @include setMinRwd{
            display: block;
        }
    }

    // 手機用的漢堡
    &-toggler {
        float: right;
        border: none;
        padding-right: 0;
        &:active,
        &:focus {
            outline: none;
        }

        @include pad1080{
            display: block !important;
        }

        .hamburger-toggle{
            position: relative;
            display: inline-block;
            width: 50px;
            height: 50px;
            z-index: 11;
            .hamburger {
                position: absolute;
                transform: translate(-50%, -50%) rotate(0deg);
                left: 50%;
                top: 50%;
                width: 50%;
                height: 50%;
                pointer-events: none;
                span {
                    width: 100%;
                    height: 4px;
                    position: absolute;
                    background: $colorBlack;
                    border-radius: 2px;
                    z-index: 1;
                    transition: transform 0.2s cubic-bezier(0.77,0.2,0.05,1.0), background 0.2s cubic-bezier(0.77,0.2,0.05,1.0), all 0.2s ease-in-out;
                    left: 0px;
                    &:first-child{
                        top: 10%;
                        transform-origin: 50% 50%;
                        transform: translate(0% -50%) !important;
                    }
                    &:nth-child(2){
                        top: 50%;
                        transform: translate(0,-50%);
                    }
                    &:last-child{
                        left: 0;
                        top: auto;
                        bottom: 0;
                        transform-origin: 50% 50%;
                    }
                }

                &.active{
                    span {
                        position: absolute;
                        margin: 0;
                        &:first-child{
                            top: 45%;
                            transform: rotate(45deg);
                        }
                        &:nth-child(2){
                            left: 50%;
                            width: 0px;
                        }
                        &:last-child{
                            top: 45%;
                            transform: rotate(-45deg);
                        }
                    }
                }
            }
        }

        // 
        &.fixed{
            display: none;
            position: fixed;
            left: 59%;
            top: 2%;
            z-index: 1001;
            .hamburger-toggle{
                .hamburger{
                    span {
                        background-color: #fff;
                        &:first-child{
                            transform: rotate(40deg);
                        }

                        &:last-child{
                            transform: translateY(-18px) rotate(-40deg);
                        }
                    }
                }
            }
            
            &.active{
                display: block;
            }
            
        }
    }

    &-nav{
        // 主連結的link
        &.link{
            flex: 1;
            justify-content: space-between;

            @include setMinRwd{
                position: relative;
                flex: 1;
                padding-left: 1em;
                padding-right: 1em;
                margin: auto;
                justify-content: space-around;
                
                .nav-link{
                    flex-direction: column;
                    
                    --min-size: 18;
                    --max-size: 20;
                    --font-size: calc((var(--min-size) * 1px) + (var(--max-size) - var(--min-size)) * ((100cqw - 320px) / (1920 - 320)));
                    font-size: clamp(var(--min-size) * 1px, var(--font-size), var(--max-size) * 1px);

                    @include setWidthRwd(1200px,1400px){
                        --min-size: 16;
                        --max-size: 18;
                    }
                }

                .nav-item{
                    position: relative;
                    flex: 1;
                    text-align: center;
                    padding-left: 1em !important;
                    padding-right: 1em !important;

                    &:not(:last-child){
                        &::after{
                            content: url('../images/nav-item-dot.jpg');
                            position: absolute;
                            right: 0;
                            top: 50%;
                            transform: translateY(-50%);
                        }
                    }

                    & > .dropdown-menu{
                        left: 50%;
                        transform: translate3d(-50%, 0px, 0);
                        background-color: transparent;
                        box-shadow: none;
                        padding-top: 30px !important;

                        .dropdown-menu-list{
                            background-color: $colorMain;
                            box-shadow: 0 5px 10px 0 rgba(138, 155, 165, 0.15);
                            padding: 6px;
                        }

                        .dropdown-item{
                            color:#fff;
                            padding-bottom: 6px;
                            &:hover{
                                color: $colorItem;
                            }
                        }
                    }

                    &:hover,
                    &.active{
                        &::after{
                            display: block;
                        }
                    }

                    &:hover{
                        & > .dropdown-menu{
                            display: block;
                            opacity: 1;
                            visibility: visible;
                            max-height: inherit;
                        }
                    }
                }
            }

            @include pad1080{
                flex-direction: column;
                .nav-item{
                    padding-left: 1.5rem !important;
                    padding-right: 0 !important;
                }
            }
        }

        //主連結的資訊
        &.info{
            .nav-item{
                display: flex;
                background-color: #f6f6f6;
                &:nth-child(even){
                    background-color: #e8e8e8;
                }
                .nav-link{
                    --min-size: 15;
                    --max-size: 16;
                    --font-size: calc((var(--min-size) * 1px) + (var(--max-size) - var(--min-size)) * ((100cqw - 320px) / (1920 - 320)));
                    font-size: clamp(var(--min-size) * 1px, var(--font-size), var(--max-size) * 1px);
                    align-items: center;
                    margin: 0.75rem 1.5rem;
                }
            }

            @include setWidthRwd(1101px,1400px){
                .nav-item{
                    .nav-link{
                        margin: 1rem 0.5rem;
                    }
                }
            }

            @include pad1080{
                flex-wrap: wrap;
                &>li{
                    flex: 1;
                    
                    &.nav-item-search,
                    &.nav-item-social{
                        display: none;
                    }

                    &.nav-item-lang{
                        // flex: 0 0 calc(100% - 3rem);
                        // max-width: calc(100% - 3rem);
                        flex: 0 0 100%;
                        max-width: 100%;
                        margin-top: 0.5rem;
                        order: 1;
                    }
                }
            }
        }
    }

    // 下拉
    &-mobile-icon{
        @include setSize(80px,40px);
        color: #000 !important;
        text-align: right;
        padding-right: 18px;
        position: absolute;
        right: 0;
        top: 3px;
        line-height: 40px;
        &::after {
            display: none;
        }

        &.show{
            .bi{
                transform: rotate(180deg);
            }
        }
    }
}

// 
.nav{
    &-link{
        position: relative;
        display: inline-block;
        font-size: 0.95em;
        color: #696969 !important;
        font-weight: 500;
        padding: 5px 0 !important;
        transition: all 200ms linear;
        @include fontSize(20px);
        @include setWidthRwd(900px, 1100px){
            @include fontSize(17px)
        }

        @include pad1080{
            padding: 10px 0 !important;
            br{
                display: none;
            }
        }
    }

    &-item{
        position: relative;
        transition: all 200ms linear;

        &.active,
        &:hover{
            .nav-link{
                color:$colorBlack !important
            }
        }

        .icon {
            flex: none;
            svg{
                vertical-align: middle;
                margin-top: -3px;
                margin-right: 10px !important;
            }

            .bi-search{
                font-size: 1.5em;
                margin-top: 2.5px;
            }

            .bi-caret-down-fill{
                margin-left: 5px;
                font-size: 14px;
            }
        }

        // -reset在新版編輯器的 .container img { margin: 1.4rem 0 1rem; }
        img{
            margin: 0 !important;
        }

        @include pad1080{
            &::after{
                display: none;
            }
            &::before {
                position: absolute;
                display: block;
                top: 24px;
                left: 5px;
                width: 11px;
                height: 1px;
                content: "";
                border: none;
                background-color: #000;
            }
        }
    }
}

// 語言在手機的樣式
@include pad1080{
    .nav-item{
        &.nav-item-lang{
            .nav-link{
                .icon{
                    &:last-child{
                        display: none !important;
                    }
                }
            }
    
            .dropdown-menu {
                position: relative;
                opacity: 1;
                width: 100%;
                max-height: 100%;
                display: flex;
                visibility: visible;
                padding: 0 !important;
                transform: translate3d(0, 5px, 0);
                .dropdown-item {
                    @include fontSize(16px);
                    text-align: center;
                    line-height: 2;
                    background-color: $colorMain;
                    color:#fff;
                    border-radius: 0;
                    &+.dropdown-item{
                        margin-left: 4px;
                    }
                }
            }
        }
    }
}


/* #Primary style
================================================== */

.bg-light {
	background-color: #fff !important;
    transition: all 200ms linear;
}

.dropdown{
    &-menu {
        padding: 10px!important;
        margin: 0;
        font-size: 13px;
        letter-spacing: 1px;
        color: #212121;
        background-color: #fcfaff;
        border: none;
        border-radius: 3px;
        box-shadow: 0 5px 10px 0 rgba(138, 155, 165, 0.15);
        transition: all 200ms linear;
    }

    &-toggle{
        &::after {
            display: none;
        }
    }
    &-item {
        @include fontSize(17px);
        padding: 7.5px 15px 3px;
        color: #212121;
        border-radius: 2px;
        transition: all 200ms linear;
        &:hover, 
        &:focus {
            color: #fff;
            background-color: $colorMain;
        }
    }
}

.nav-item{
    .dropdown-menu {
        transform: translate3d(0, 10px, 0);
        visibility: hidden;
        opacity: 0;
        // min-width: auto;
        max-height: 0;
        display: none;
        padding: 0;
        margin: 0;
        transition: all 200ms linear;
        &.show{
            display: block;
            opacity: 1;
            visibility: visible;
            max-height: 999px;
            transform: translate3d(-10px, 0px, 0);
        }
    }
    
    &-lang {
        .dropdown-toggle{
            @include pad1080{
                display: none !important;
            }
        }
    }
}

// 手機Header放置主ICON
.nabar-icon-mobile{
    @include pad1080{
        display: flex !important;
        align-items: center;
        justify-content: flex-end;
        padding-right: 1em;
        .icon{
            margin-left: 5px;
        }
        .icon-search{
            width: 25px;
            margin-right: 10px;
        }
    }
}

/* #Media
================================================== */

@include pad{
	.dropdown{
        &-toggle{
            &[aria-expanded="true"]{
                &::after{
                    transform: rotate(90deg);
                    opacity: 0;
                }
            }
        }
    }
}

// 搜尋後的表單
.search-form {
    &List{
        position: absolute;
        top: 129px;
        width: 100%;
        height: 100vh;
        background: rgba(0,0,0,.75);
        z-index: 1;
        transition: 0.5s;
        display: none;
        &.open {
            display: block;
        }

        @include setWidthRwd(1301px,1400px){
            top: 6.25em;
        }

        @include setWidthRwd(1025px,1300px){
            top: 5.25em;
        }

        @include setWidthRwd(800px,1024px){ 
            top: 8.75em;
        }
        @include pad{
            top: 6em;
        }
    }

    &Input{
        position: absolute;
        top: 0px;
        right: 0%;
        width: 100%;
        max-height: 60px;
        overflow: hidden;
        background-color: #e1e4e7;
        max-width: 40em;

        fieldset {
            position: relative;
            margin: 0 60px 0 0;
            padding: 0;
            border: none;
        }

        input {
            display: block;
            height: 60px;
        }

        input[type="search"] {
            width: 100%;
            padding: 0 5px 0 22px;
            background: transparent;
            border: none;
        }

        input[type="submit"] {
            position: absolute;
            bottom: 0;
            right: 0;
            width: 80px;
            margin: 0;
            padding: 0;
            font-weight: 700;
            text-transform: uppercase;
            color: white;
            background: $colorMain;
            border: none;
            cursor: pointer;
        }
    }
}