.article{
    &-onesetin{
        @extend %setFlex-center;
        justify-content: space-between;
        padding: 5px 30px 20px 30px;
        width: 100%;
        border-bottom: 1px solid #cfcfcf;

        @include setMaxRwd{
            padding-left: 0.25em;
            padding-right: 0.25em;
        }
    }

    &-txtcon{
      color: #000;
      font-weight: bold;

      &-head{
        --min-size: 20;
        --max-size: 25;
        --font-size: calc((var(--min-size) * 1px) + (var(--max-size) - var(--min-size)) * ((100cqw - 320px) / (1920 - 320)));
        font-size: clamp(var(--min-size) * 1px, var(--font-size), var(--max-size) * 1px);
        margin-top: 0.5em;
        margin-left: 5px;
      }
    }

    &-author{
        text-align: right;
        margin-top: 15px;
        .authorbox{
            margin-bottom: 1em;
        }
        @include setMaxRwd{
            width: 100%;
            margin-top: 0.25em;
        }
    }

    &con{
        box-shadow: 0 9px 10px -8px rgba(0,0,0,0.15);
        background-color: #fcfcfc;
        padding: 30px;
        padding-right: 25px;
        line-height: 1.7;
    }
}


// 作者
.authorbox{
    --min-size: 13;
    --max-size: 13;
    --font-size: calc((var(--min-size) * 1px) + (var(--max-size) - var(--min-size)) * ((100cqw - 320px) / (1920 - 320)));
    font-size: clamp(var(--min-size) * 1px, var(--font-size), var(--max-size) * 1px);

    display: inline-block;
    color: #fff;
    background: $colorItem;
    border-radius: 5px;
    padding: 9px 18px 6px;
}

.datebox{
    --min-size: 14;
    --max-size: 16;
    --font-size: calc((var(--min-size) * 1px) + (var(--max-size) - var(--min-size)) * ((100cqw - 320px) / (1920 - 320)));
    font-size: clamp(var(--min-size) * 1px, var(--font-size), var(--max-size) * 1px);
    margin-bottom: 0;
}

// 
.otherarticle{
  @extend %setFlex-center;
  justify-content: space-between;
  padding: 30px 20px;
  @include setMinRwd{
    flex-wrap: nowrap;
    .prenextbox{
        padding-right: 4em;
    }
    .btn-wrap{
        width: auto;
        margin: 0;
    }
  }
}

// 上下頁
.prenext{
    &-list{
        display: flex;
        font-size: 17px;
        margin-bottom: 20px;
        &-head{
            flex-shrink: 0;
        }
        a{
            display: inline-block;
            color: #000;
            font-weight: 600;
        }
    }
}